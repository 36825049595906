import { Layout } from "antd";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { Typography, message } from "antd";
import { createForgotPassword } from "../../apis/apis";
const { Title, Link } = Typography;

export const ForgotPasswordPage = () => {
  const onFinish = async (values) => {
    try {
      await createForgotPassword(values.email);
      message.success(`Reset password email sent`);
    } catch (e) {
      console.log(e);
      message.error(`Something went wrong`);
    }
  };

  return (
    <Layout className="bg-white flex-row">
      <div className="flex flex-col flex-1 p-20">
        <Typography>
          <Title level={2}>Forgot Password</Title>
        </Typography>
        <ForgotPasswordForm onFinish={onFinish} />
      </div>
      <div className="flex p-20 flex-1">
        <Typography>
          <Title level={2}>Already have a account?</Title>
          <Link href="/dashboard/login">Click here</Link>
        </Typography>
      </div>
    </Layout>
  );
};
