import React, { useEffect, useState } from "react";
import { Modal, Form, Select, Button, Spin, Input } from "antd";
import { listPermissionOptions } from "../../../apis/admin";

export const EditAccessModal = ({ data, onClose, onSubmit }) => {
  const [form] = Form.useForm();
  const [currentAccess, setAccess] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAccessibilityOptions = async () => {
      setLoading(true);
      try {
        const response = await listPermissionOptions();
        setAccess(response.permission);
        // Convert initial data.permissions to a format compatible with Select
        const initialValues = data.permissions.map((p) => ({
          label: p.permission, // Display label
          value: p.id, // Unique value combining label and ID
        }));
        form.setFieldsValue({ permissions: initialValues, email: data.email });
      } catch (error) {
        console.error("Failed to fetch accessibility options:", error);
      }
      setLoading(false);
    };

    fetchAccessibilityOptions();
  }, [data, form]);

  const onModalClose = () => {
    if (onClose) onClose();
  };

  const onFinish = (values) => {
    if (onSubmit) {
      const initialPermissionIds = data.permissions.map((p) => p.id);
      const submittedPermissionIds = values.permissions.map((item) => {
        return item.value;
      });


      const permissionsToBeDeleted = initialPermissionIds.filter(
        (id) => !submittedPermissionIds.includes(id)
      );

      const newAddPermission = submittedPermissionIds.filter(
        (id) => !initialPermissionIds.includes(id)
      );

      onSubmit({
        submittedPermissionIds: newAddPermission,
        permissionsToBeDeleted: permissionsToBeDeleted,
        email: values.email,
      });
    }
  };

  const onDeletePermission = () => {
    const permissionIds = data.permissions.map((p) => p.id);
    onSubmit({
      submittedPermissionIds: [],
      permissionsToBeDeleted: permissionIds,
    });
  }

  return (
    <Modal
      title="Edit Access"
      open={true}
      onCancel={onModalClose}
      footer={[
        <Button key="back" onClick={onModalClose}>
          Cancel
        </Button>,
        <Button onClick={onDeletePermission} danger>
          Delete Access
        </Button>,
        <Button
          className="bg-blue-500"
          key="submit"
          type="primary"
          onClick={() => form.submit()}
        >
          Save Changes
        </Button>,
      ]}
      className="sm:max-w-7xl md:max-w-7xl"
      width={800}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          name="editAccessModal"
        >
          <Form.Item
            name="email"
            label="User Email"
          >
            <Input disabled={true}  />
          </Form.Item>

          <Form.Item
            name="permissions"
            label="Select Permissions"
            rules={[
              {
                required: true,
                message: "Please select at least one permission!",
              },
            ]}
          >
            <Select
              mode="multiple"
              labelInValue // Important for capturing both label and value
              placeholder="Select permissions"
              options={currentAccess.map(({ id, name }) => ({
                label: name, // Display label
                value: id, // Unique value combining label and ID
              }))}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};
