import { Layout } from "antd";
import NavBarHome from "../components/Navigations/NavbarHome";
import Footer from "../components/Footer/Footer";

const AppLayout = ({ children }) => {
  return (
    <Layout className="bg-white">
      <NavBarHome />
      <Layout className="bg-white">{children}</Layout>
      <Footer />
    </Layout>
  );
};
export default AppLayout;
