import { serverAPIAxios, _generateAuthoriseHeaderForm } from './init'

export const downloadSeries = async (studyId, seriesIds) => {
    try {
        const response = await serverAPIAxios.get(`/studies/download-series`, {
            headers: _generateAuthoriseHeaderForm(),
            responseType: "blob", // Important to handle binary data
            params: { // Add query parameters here
                fileId: studyId,
                seriesIds: seriesIds.join(','), // Assuming seriesIds is an array and needs to be joined by commas
            },
        });

        return response.data;
    } catch (error) {
        console.error("Error download series", error);
        throw error;
    }
};
