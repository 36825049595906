import { serverAPIAxios, _generateAuthoriseHeader } from "./init";

export const listAllGatewayManageByAdmin = async () => {
  try {
    const response = await serverAPIAxios.get(`/gateway/admin`, {
      headers: _generateAuthoriseHeader(),
    });

    return response.data;
  } catch (error) {
    console.error("Error listAllGatewayManageByAdmin", error);
    throw error;
  }
};

export const listAllDicomeNodeByGatewayId = async (id) => {
  try {
    const response = await serverAPIAxios.get(`/dicom-node/${id}`, {
      headers: _generateAuthoriseHeader(),
    });

    return response.data;
  } catch (error) {
    console.error("Error listAllDicomeNodeByGatewayId", error);
    throw error;
  }
};

export const listAllDicomeNodeByGatewayIdWithEmailFilter = async (
  gateway_id,
  email
) => {
  try {
    const response = await serverAPIAxios.post(
      `/dicom-node/admin/query-with-email`,
      {
        gateway_id,
        email,
      },
      {
        headers: _generateAuthoriseHeader(),
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error listAllDicomeNodeByGatewayIdWithEmailFilter", error);
    throw error;
  }
};

export const listAllPermissionByDicomNodeId = async (id) => {
  try {
    const response = await serverAPIAxios.get(`/permission/node/access/${id}`, {
      headers: _generateAuthoriseHeader(),
    });

    return response.data;
  } catch (error) {
    console.error("Error listAllPermissionByDicomNodeId", error);
    throw error;
  }
};

export const listPermissionOptions = async () => {
  try {
    const response = await serverAPIAxios.get(`/permission/options`, {
      headers: _generateAuthoriseHeader(),
    });

    return response.data;
  } catch (error) {
    console.error("Error listPermissionOptions", error);
    throw error;
  }
};

export const createAccessPermission = async (
  node_id,
  gateway_id,
  permission,
  email
) => {
  try {
    const response = await serverAPIAxios.post(
      `/permission/node/access/${node_id}`,
      {
        permission,
        gateway_id,
        email,
      },
      {
        headers: _generateAuthoriseHeader(),
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error createAccessPermission", error);
    throw error;
  }
};

export const deleteAccessPermission = async (id) => {
  try {
    const response = await serverAPIAxios.delete(
      `/permission/node/access/${id}`,
      {
        headers: _generateAuthoriseHeader(),
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error deleteAccessPermission", error);
    throw error;
  }
};

export const editGateway = async (data) => {
  try {
    const response = await serverAPIAxios.put(`/dicom-node`, data, {
      headers: _generateAuthoriseHeader(),
    });

    return response.data;
  } catch (error) {
    console.error("Error editGateway", error);
    throw error;
  }
};

export const requestPermission = async (data) => {
  try {
    const response = await serverAPIAxios.post(
      `/permission/request-permission`,
      data,
      {
        headers: _generateAuthoriseHeader(),
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error requestPermission", error);
    throw error;
  }
};

export const listRequestPermission = async () => {
  try {
    const response = await serverAPIAxios.get(
      `/permission/request-permission`,
      {
        headers: _generateAuthoriseHeader(),
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error listRequestPermission", error);
    throw error;
  }
};

export const listRequestPendingPermission = async () => {
  try {
    const response = await serverAPIAxios.get(
      `/permission/request-pending-permission`,
      {
        headers: _generateAuthoriseHeader(),
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error listRequestPendingPermission", error);
    throw error;
  }
};

export const deleteRequestPermission = async (id) => {
  try {
    const response = await serverAPIAxios.delete(
      `/permission/request-permission/${id}`,
      {
        headers: _generateAuthoriseHeader(),
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error deleteRequestPermission", error);
    throw error;
  }
};

export const approveRequestPermission = async (id) => {
  try {
    const response = await serverAPIAxios.put(
      `/permission/request-permission/${id}`,
      {},
      {
        headers: _generateAuthoriseHeader(),
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error approveRequestPermission", error);
    throw error;
  }
};

export const setUserAsAdminGateway = async (auth0_user_id, gateway_id) => {
  try {
    const response = await serverAPIAxios.post(
      `/users/admin/${auth0_user_id}/${gateway_id}`,
      {},
      {
        headers: _generateAuthoriseHeader(),
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error setUserAsAdminGateway", error);
    throw error;
  }
};

export const setUserAsUserGateway = async (auth0_user_id, gateway_id) => {
  try {
    const response = await serverAPIAxios.post(
      `/users/admin/user/${auth0_user_id}/${gateway_id}`,
      {},
      {
        headers: _generateAuthoriseHeader(),
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error setUserAsUserGateway", error);
    throw error;
  }
};
