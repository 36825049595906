import { create } from 'zustand'
import { persist } from 'zustand/middleware';

const createGatewayStore = (id) => create(persist(
    (set) => ({
        gatewayData: [],
        selectedDestinationGateway: null,
        selectedSourceGateway: null,
        sourceGatewayId: null,
        destinationGateways: [],
        gatewaySourceListener: [],
        gatewayDestinationListener: [],
        setGatewaySourceListener: (gatewaySourceListener) => set({ gatewaySourceListener }),
        setSelectedDestinationGateway: (gateway) => set({ selectedDestinationGateway: gateway }),
        setSelectedSourceGateway: (gateway) => set({ selectedSourceGateway: gateway }),
        setGatewayDestinationListener: (gatewayDestinationListener) => set({ gatewayDestinationListener }),
        setSourceGatewayId: (id) => set({ sourceGatewayId: id }),
        setGatewayData: (data) => set({ gatewayData: data }),
        setDestinationGateways: (destinationGateways) => set({ destinationGateways }),
    }),
    {
        name: `gatewayStore-${id}`, // unique name
        getStorage: () => sessionStorage, // (optional) by default the 'localStorage' is used
    }
));

export const DashboardGatewayStore = createGatewayStore('dashboard-gateway');
export const ImageTransferingStore = createGatewayStore('image-transfering-gateway');
export const DashboardAIGatewayStore = createGatewayStore('dashboard-gateway-ai');
