export const AnonymizeColumnsToBeReplaced = [
  "PatientName",
  "PatientBirthDate",
  "PatientSex",
  "PatientAge",
  "PatientWeight",
  "PatientAddress",
  "PatientTelephoneNumbers",
  "AdditionalPatientHistory",
  "PatientComments",
  "InstitutionName",
  "InstitutionalDepartmentName",
  "InstitutionAddress",
  "ReferringPhysicianName",
  "NameOfPhysiciansReadingStudy",
];