import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import AppLayout from './layouts/HomeLayout';
import DashboardLayout from './layouts/DashboardLayout';
import { LoginPage, SignupPage, ForgotPasswordPage } from './pages/Users';
import { DashboardPage, StudiesPage } from './pages/Dashboard';
import { BatchTransferingPage } from './pages/BatchTransfering';
import { AccessControlPage } from './pages/Permission';
import { AIPage } from './pages/AI';
import { AppContext } from './libs/context';

// Simplifies route protection
const ProtectedRoute = ({ children }) => {
  return isValidJWT() ? children : <Navigate to="/dashboard/login" replace />;
};

const PublicRoute = ({ children }) => {
  return !isValidJWT() ? children : <Navigate to="/dashboard" replace />;
};

// Checks the validity of JWT token
function isValidJWT() {
  const token = localStorage.getItem('token');
  if (!token) return false;

  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.exp > Date.now() / 1000;
  } catch (error) {
    return false;
  }
}

// Layout wrapper for simplifying layout usage
const LayoutWrapper = ({ layout: Layout, component: Component, ...rest }) => {
  const [currentModal, setCurrentModal] = useState({
    modal: null,
    data: null,
  });

  return <AppContext.Provider value={{ currentModal, setCurrentModal }}>
    <Layout>
      <Component {...rest} />
    </Layout>
  </AppContext.Provider>
}


function App() {
  useEffect(() => {
    window.onbeforeunload = function () {
      sessionStorage.clear();
    };
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" index element={<PublicRoute><Navigate to="/dashboard/login" replace /></PublicRoute>} />
        <Route path="/dashboard/login" element={<PublicRoute><LayoutWrapper component={LoginPage} layout={AppLayout} /></PublicRoute>} />
        <Route path="/dashboard/forgot" element={<PublicRoute><LayoutWrapper component={ForgotPasswordPage} layout={AppLayout} /></PublicRoute>} />
        <Route path="/dashboard/signup" element={<PublicRoute><LayoutWrapper component={SignupPage} layout={AppLayout} /></PublicRoute>} />
        <Route path="/dashboard" element={<ProtectedRoute><LayoutWrapper component={DashboardPage} layout={DashboardLayout} /></ProtectedRoute>} />
        <Route path="/dashboard/studies" element={<ProtectedRoute><LayoutWrapper component={StudiesPage} layout={DashboardLayout} /></ProtectedRoute>} />
        <Route path="/dashboard/batch-transfering" element={<ProtectedRoute><LayoutWrapper component={BatchTransferingPage} layout={DashboardLayout} /></ProtectedRoute>} />
        <Route path="/dashboard/permission" element={<ProtectedRoute><LayoutWrapper component={AccessControlPage} layout={DashboardLayout} /></ProtectedRoute>} />
        <Route path="/dashboard/ai" element={<ProtectedRoute><LayoutWrapper component={AIPage} layout={DashboardLayout} /></ProtectedRoute>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
