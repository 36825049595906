import { create } from 'zustand'
import { persist } from 'zustand/middleware';

export const useExcelListKeyStore = create(persist(
    (set) => ({
        fileList: [],
        setFileList: (file) => set((state) => {
            let fileList = state.fileList || [];
            fileList = [
                ...file
            ]
            return { fileList };
        }),
    }),
    {
        name: 'excelFileList',
        getStorage: () => sessionStorage,
    }
));

export const ExcelRawDataStore = create(persist(
    (set, get) => ({
        excelRawData: [],
        setExcelRawData: (excelRawData) => set({ excelRawData }),
        excelTableData: [],
        setExcelTableData: (excelTableData) => set({ excelTableData }),
    }),
    {
        name: 'excelRawDataStore',
        getStorage: () => sessionStorage,
    }
));
