import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Input, Spin, message } from "antd";
import { getUserBasicInformation } from '../../apis/apis'


export const ChangeInformationModal = ({
  isVisible,
  onClose,
  onSubmit,
}) => {
  const [userInformation, setUserInformation] = useState({});
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    const fetchUserInformation = async () => {
      try {
        if (isLoading) return;
        setLoading(true);
        const resp = await getUserBasicInformation();
        if (resp && resp.data) {
          setUserInformation(resp.data);
        }
      } catch (e) {
        console.error(e);
        message.error("Failed to get user information");
      } finally {
        setLoading(false);
      }
    }

    fetchUserInformation();
  }, []);

  const [form] = Form.useForm();

  const handleFinish = (values) => {
    onSubmit(values);
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      title="Change Information"
      width={500}
      visible={isVisible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          className="bg-blue-500 hover:bg-blue-700"
          onClick={() => form.submit()}
        >
          Submit
        </Button>,
      ]}
    >
      {isLoading && <Spin />}
      {!isLoading && (
        <Form
          form={form}
          initialValues={userInformation}
          layout="vertical"
          onFinish={handleFinish}
        >
          <Form.Item
            label="First Name"
            name="first_name"
            rules={[{ required: true, message: 'Please enter your first name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please enter your email' }]}
          >
            <Input disabled={true} />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="last_name"
            rules={[{ required: true, message: 'Please enter your last name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Address"
            name="address"
            rules={[{ required: true, message: 'Please enter your address!' }]}
          >
            <Input.TextArea />
          </Form.Item>
        </Form>
      )}

    </Modal>
  );
};

export default ChangeInformationModal;