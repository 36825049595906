import React from "react";
import { Form, Input, Button, message, Typography } from "antd";
import { loginUser } from "../../apis/apis";
const { Link } = Typography;

const LoginForm = () => {
  const onFinish = async (values) => {
    try {
      console.log("Received values of form: ", values);
      const result = await loginUser(values.email, values.password);
      if (result) {
        message.success(`Successfully login `);
        console.log(result);
        localStorage.setItem("token", result.access_token);
        window.location.reload(true);
      }
    } catch (e) {
      console.log(e);
      if (e.response) {
        message.error(e.response.data.message);
      }
    }
  };

  return (
    <div className="flex">
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ width: 300 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input onPressEnter={() => onFinish()} />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password onPressEnter={() => onFinish()} />
        </Form.Item>
        <Form.Item className="flex" wrapperCol={{ offset: 8, span: 16 }}>
          <Button className="bg-blue-500" type="primary" htmlType="submit">
            Login
          </Button>
        </Form.Item>

        <Link className="pl-5" href="/dashboard/forgot" target="_blank">
          Forgot password ?
        </Link>
      </Form>
    </div>
  );
};

export default LoginForm;
