import React, { useEffect, useState } from "react";
import { Modal, Form, Select, Button, Spin, Input, message } from "antd";
import {
  listAllGatewayByEmail,
  listAllDicomNodeByGatewayIDForRequestAccess,
} from "../../apis/gateway";
import { listPermissionOptions } from "../../apis/admin";

export const RequestAccessModal = ({ onClose, onSubmit, data }) => {
  const [form] = Form.useForm();
  const [gateways, setGateways] = useState([]);
  const [dicomNodes, setDicomNodes] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchPermissions = async () => {
      const response = await listPermissionOptions();
      setPermissions(response.permission);
    };

    fetchPermissions();
  }, []);

  const onGatewaySelect = async (gatewayId) => {
    setLoading(true);
    const response = await listAllDicomNodeByGatewayIDForRequestAccess(gatewayId);
    setDicomNodes(response.data);
    setLoading(false);
    form.setFieldsValue({ dicomNode: undefined });
  };

  const onModalClose = () => {
    if (onClose) onClose();
  };

  const onFinish = async (values) => {
    setLoading(true);
    if (onSubmit)
      onSubmit({
        email: values.email,
        node_id: values.node_id,
        gateway_id: values.gateway_id,
        permission_ids: values.permission_ids,
      });
    setLoading(false);
  };

  useEffect(() => {
    form.setFieldValue("email", data.email);
  }, []);

  const [searchTerm, setSearchTerm] = useState('')
  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if(searchTerm && searchTerm.length > 0) {
        try {
          const response = await listAllGatewayByEmail(searchTerm);
          setGateways([]);
          
          if(response.data && response.data.length > 0) { 
            setGateways(response.data);
            message.success("Found gateways results");
          }
        } catch(e) {
          console.log(e);
          message.error("Something went wrong cannot search")
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  const onKeywordChange = async (e) => {
    const value = e.target.value;
    setSearchTerm(value)
  };

  return (
    <Modal
      title="Raise an Access"
      open={true}
      onCancel={onModalClose}
      footer={[
        <Button key="back" onClick={onModalClose}>
          Cancel
        </Button>,
        <Button
          className="bg-blue-500"
          key="submit"
          type="primary"
          onClick={() => form.submit()}
        >
          Create
        </Button>,
      ]}
      className="sm:max-w-7xl md:max-w-7xl"
      width={800}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          name="RequestAccessModal"
        >
          <Form.Item
            disabled
            name="email"
            label="User Email"
            rules={[
              {
                required: true,
                message: "Please input user email!",
                type: "email",
              },
            ]}
          >
            <Input placeholder="Enter user's email" disabled />
          </Form.Item>
          <Form.Item name="search_gateway" label="Gateway Email">
            <Input onChange={onKeywordChange} placeholder="Enter email" />
          </Form.Item>
          <Form.Item
            name="gateway_id"
            label="Select Gateway"
            rules={[{ required: true, message: "Please select a gateway!" }]}
          >
            <Select
              showSearch
              placeholder="Select a gateway"
              optionFilterProp="children"
              onChange={onGatewaySelect}
            >
              {gateways.map((gateway) => (
                <Select.Option key={gateway.id} value={gateway.id}>
                  {gateway.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="node_id"
            label="Select DICOM Node"
            rules={[{ required: true, message: "Please select a DICOM node!" }]}
          >
            <Select
              showSearch
              placeholder="Select a DICOM node"
              optionFilterProp="children"
              disabled={dicomNodes.length === 0}
            >
              {dicomNodes.map((node) => (
                <Select.Option key={node.id} value={node.id}>
                  {node.name + "-" + node.ip}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="permission_ids"
            label="Select Permissions"
            rules={[
              {
                required: true,
                message: "Please select at least one permission!",
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Select permissions"
              optionFilterProp="children"
            >
              {permissions.map((permission) => (
                <Select.Option key={permission.id} value={permission.id}>
                  {permission.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};
