import React from 'react';
import { Button } from "antd";

const AnonymousToggleButton = ({ onSend }) => {
    return (
        <>
            <Button
                data-testid="send-image-button"
                onClick={() => {
                    onSend(false)
                }}
                type="primary"
                className="bg-blue-600"
            >
                Send with PHI
            </Button>
            <Button
                data-testid="turn-on-anonymous"
                onClick={() => onSend(true)}
                type="primary"
                className="bg-blue-600 ml-2"
            >
                Send with Anonymization
            </Button>
        </>)
};

export default AnonymousToggleButton;