import { serverAPIAxios, _generateAuthoriseHeader, _generateAuthoriseHeaderForm } from './init'


// User API calls
export const registerAndAuthenticateUser = async (data) => {
  try {
    const response = await serverAPIAxios.post("/users/", {
      ...data,
      email: data.email.toLowerCase(),
    });
    return response.data;
  } catch (error) {
    console.error("Error registerAndAuthenticateUser", error);
    throw error;
  }
};


export const updateUserPassword = async (password) => {
  try {
    const response = await serverAPIAxios.post("/users/update-password", {
      password,
    }, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error updateUserPassword", error);
    throw error;
  }
};

export const updateUserInformation = async (data) => {
  try {
    const response = await serverAPIAxios.post("/users/basic-information", {
      data,
    }, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error updateUserInformation", error);
    throw error;
  }
};

export const loginUser = async (email, password) => {
  try {
    const data = {
      email: email.toLowerCase(),
      password,
    };
    const response = await serverAPIAxios.post("/users/login", data);
    return response.data;
  } catch (error) {
    console.error("Error loginUser", error);
    throw error;
  }
};

// Gateway API calls
export const getCurrentGateways = async (company_id) => {
  try {
    const response = await serverAPIAxios.post(
      "/gateway",
      { company_id },
      {
        headers: _generateAuthoriseHeader(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error getCurrentGateways", error);
    throw error;
  }
};

export const getUserBasicInformation = async () => {
  try {
    const response = await serverAPIAxios.get(
      "/users/basic-information",
      {
        headers: _generateAuthoriseHeader(),
      }

    );
    return response.data;
  } catch (error) {
    console.error("Error getUserBasicInformation", error);
    throw error;
  }
};

export const sendFileToGateway = async (
  src_gateway_id,
  dest_gateway_id,
  studyInstanceID
) => {
  try {
    const response = await serverAPIAxios.post(
      "/gateway/send/file",
      {
        dest_gateway_id,
        src_gateway_id,
        studyInstanceID,
      },
      {
        headers: _generateAuthoriseHeader(),
        timeout: 50000000,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error sending file to gateway:", error);
    throw error;
  }
};

export const listAllStudiesByUserID = async (page, pageSize, filterOptions) => {
  try {
    const response = await serverAPIAxios.post(
      `/studies/list?page=${page}&pageSize=${pageSize}`,
      {
        filter: filterOptions,
      },
      {
        headers: _generateAuthoriseHeader(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error sending file to gateway:", error);
    throw error;
  }
};

export const downloadStudy = async (study_id) => {
  try {
    const response = await serverAPIAxios.get(`/studies/download/${study_id}`, {
      headers: _generateAuthoriseHeaderForm(),
      responseType: "blob", // Important to handle binary data
    });
    return response.data;
  } catch (error) {
    console.error("Error upload file:", error);
    throw error;
  }
};

export const editStudy = async (id, dicom_data) => {
  try {
    const response = await serverAPIAxios.put(
      `/studies/${id}`,
      {
        dicom_data,
      },
      {
        headers: _generateAuthoriseHeader(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error editStudy:", error);
    throw error;
  }
};

export const deleteStudy = async (instance_id, study_id) => {
  try {
    const response = await serverAPIAxios.delete(`/studies`, {
      headers: _generateAuthoriseHeader(),
      data: { study_id, instance_id },
    });
    return response.data;
  } catch (error) {
    console.error("Error upload file:", error);
    throw error;
  }
};

export const editGateway = async (gateway_id, name) => {
  try {
    const response = await serverAPIAxios.put(
      `/gateway/`,
      {
        gateway_id,
        name,
      },
      {
        headers: _generateAuthoriseHeader(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error edit gateway:", error);
    throw error;
  }
};

export const getAllStudiesByGatewayID = async (gateway_id, name) => {
  try {
    const response = await serverAPIAxios.post(
      `/gateway/studies`,
      {
        gateway_id,
        name,
      },
      {
        headers: _generateAuthoriseHeader(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error edit gateway:", error);
    throw error;
  }
};

export const getGatewayStatus = async (gateway_id) => {
  try {
    const response = await serverAPIAxios.post(
      `/gateway/status`,
      {
        gateway_id,
      },
      {
        headers: _generateAuthoriseHeader(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error edit gateway:", error);
    throw error;
  }
};

export const getJobsStatus = async (
  gateway_id,
  studyInstanceID,
  destination
) => {
  try {
    const response = await serverAPIAxios.post(
      `/gateway/jobs/status`,
      {
        gateway_id,
        studyInstanceID,
        destination,
      },
      {
        headers: _generateAuthoriseHeader(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error edit gateway:", error);
    throw error;
  }
};

export const getTags = async (es_id) => {
  try {
    const response = await serverAPIAxios.post(
      `/studies/tags/${es_id}`,
      {},
      {
        headers: _generateAuthoriseHeader(),
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error get tags:", error);
    throw error;
  }
};

export const createForgotPassword = async (email) => {
  try {
    const response = await serverAPIAxios.post(
      `/users/forgot_request`,
      {
        email,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error get tags:", error);
    throw error;
  }
};

export const listAllSeriesByStudyID = async (studyID) => {
  try {
    const response = await serverAPIAxios.get(`/studies/detail/series/${studyID}`, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error listAllSeriesByStudyID", error);
    throw error;
  }
};

export const getPermissionByUsers = async () => {
  try {
    const response = await serverAPIAxios.get(`/permission/user`, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error getPermissionByUsers", error);
    throw error;
  }
}

export const deleteSeriesByStudyId = async (series) => {
  try {
    const response = await serverAPIAxios.delete(`/studies/series/batch`, {
      headers: _generateAuthoriseHeader(),
      data: {
        series
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error getPermissionByUsers", error);
    throw error;
  }
}
