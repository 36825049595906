import { useEffect, useState } from 'react'
import { Table, Tooltip, Input, Button, Radio, Menu, Dropdown, Popconfirm, message, Typography } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { AIGatewayStore } from '../../../stores/ai'
import { getDicomNodes, createAiDicomNode, createAiDicomNodeCloud, deleteDicomAiNode } from '../../../apis/ai'
import { CreateDicomNodeModal } from './modals/CreateDicomNode'
import {
    listAllGatewayByUserID,
} from "../../../apis/gateway";
import { fetchGatewaysStatusAI } from '../../components/GatewayDicom/hooks/GatewayStatusCustomHook';
import { DashboardAIGatewayStore } from '../../../stores/gateway';
import { EditGatewayModal } from "./modals/EditAIGatewayModal";
import { EllipsisOutlined } from '@ant-design/icons';
import { MODAL_EDIT_DICOM_NODE } from '../../../../src/pages/constants/modals';
import { useContext } from "react";
import { AppContext } from '../../../libs/context';
const { Title } = Typography;

const { Search } = Input;

const aiGatewayColumns = [
    {
        title: "Name",
        dataIndex: "name",
        render: (_, record) => {
            return (
                <Tooltip placement="left" title={record.name}>
                    <p className='truncate w-[200px]'>{record.name}</p>
                </Tooltip>
            )
        },
    },
    {
        title: "Status",
        dataIndex: "status",
        className: "w-[150px]",
        render: (text, record) => {
            return <p>{record.status}</p>;
        },
    },
    {
        title: "IP",
        className: "w-[100px]",
        render: (text, record) => {
            return <Tooltip placement="left" title={record.listener_ip}>
                <p className='truncate w-[200px]'>{record.listener_ip}</p>
            </Tooltip>
        },
    },
    {
        title: "AI Listener Port",
        render: (text, record) => {
            return <Tooltip placement="left" title={record.listener_port}>
                <p className='truncate'>{record.listener_port}</p>
            </Tooltip>
        },
    },
    {
        title: "AET",
        dataIndex: "aet",
        className: "w-[100px]",
        render: (text, record) => {
            return <p>{record.listener_aet}</p>;
        },
    },
    {
        title: "AI Listener",
        dataIndex: "ai-listener",
        className: "w-[150px]",
        render: (text, record) => {
            return <p>{record.listener_status}</p>;
        },
    },
    {
        title: "Role",
        dataIndex: "role",
        className: "w-[100px]",
        render: (text, record) => {
            return <p>{record.type}</p>;
        },
    }
]

const fixedDestinationDicomNodesColumns = [
    {
        title: "Name",
        dataIndex: "name",
        className: "w-[150px]",
        render: (text, record) => {
            return <p>{record.name}</p>;
        },
    },
    {
        title: "IP",
        dataIndex: "ip",
        className: "w-[150px]",
        render: (text, record) => {
            return <p>{record.ip}</p>;
        },
    },
    {
        title: "Port",
        dataIndex: "port",
        className: "w-[100px]",
        render: (text, record) => {
            return <p>{record.port}</p>;
        },
    },
    {
        title: "AET",
        dataIndex: "aet",
        className: "w-[150px]",
        render: (text, record) => {
            return <p>{record.aet}</p>;
        },
    },
    {
        title: "Status",
        dataIndex: "status",
        className: "w-[150px]",
        render: (text, record) => {
            return <p>{record.status}</p>;
        },
    }
]

const GatewaysTable = ({ selectedGateway, gatewayData, columns, pagination }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const filteredDataGateways = gatewayData.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const filteredAdminEditGatewayOnly = gatewayData.filter((gateway) => {
        return gateway.type === "admin";
    });

    const [isShowGatewayManage, setShowEditGateway] = useState(false);
    const isSelectedAdminGateway = selectedGateway && selectedGateway.type === 'admin';
    return (<div><div className="flex mb-2.5">
        <Title level={4} className='mr-2.5'>AI Gateways</Title>
        <Search
            placeholder="Search gateways by name"
            onSearch={value => setSearchTerm(value)}
            style={{ width: 400 }}
        />
        {filteredAdminEditGatewayOnly.length !== 0 && isSelectedAdminGateway && gatewayData && gatewayData.length !== 0 && (
            <Button onClick={() => {
                setShowEditGateway(!isShowGatewayManage);
            }} className='ml-2.5' >Edit</Button>
        )}
    </div>
        <Table
            columns={columns}
            pagination={pagination}
            rowKey="id"
            dataSource={filteredDataGateways}
        />
        {filteredAdminEditGatewayOnly.length !== 0 && gatewayData &&
            gatewayData.length !== 0 &&
            <EditGatewayModal
                isOpen={isShowGatewayManage}
                handleClose={() => {
                    setShowEditGateway(!isShowGatewayManage);
                }}
                data={filteredAdminEditGatewayOnly}
            />}
    </div >)
}

const DicomNodesTable = ({ dicomNodes, pagination, selectedGatewayID, onCreateDicomNodeClick, disabledCreateDicom, columns }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const filteredDataDicoms = dicomNodes.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="mt-2.5">
            <div className="flex mb-2.5">
                <Title level={4} className='mr-2.5'>Destination Dicom Nodes</Title>
                <Search placeholder="Search dicom nodes by name" onSearch={value => setSearchTerm(value)} style={{ width: 400 }} />
                <Button disabled={!selectedGatewayID || disabledCreateDicom} onClick={onCreateDicomNodeClick} icon={<PlusOutlined />} className="ml-2.5">Create Dicom Node</Button>
            </div>
            <Table
                pagination={pagination}
                columns={columns}
                rowKey="id"
                dataSource={filteredDataDicoms}
            />
        </div>
    )
}

export const Gateways = () => {
    const [selectedRowKey, setSelectedRowKey] = useState(null);
    const Store = AIGatewayStore((state) => state);
    const gatewayData = DashboardAIGatewayStore((state) => state.gatewayData)
    const setGatewayData = DashboardAIGatewayStore((state) => state.setGatewayData)
    const sourceGatewayId = DashboardAIGatewayStore((state) => state.sourceGatewayId)
    const setSourceGatewayId = DashboardAIGatewayStore((state) => state.setSourceGatewayId)
    const setSelectedSourceGateway = DashboardAIGatewayStore((state) => state.setSelectedSourceGateway)
    const selectedSourceGateway = DashboardAIGatewayStore((state) => state.selectedSourceGateway);
    const dicomNodes = Store.getDicomNodes();

    const { setCurrentModal } = useContext(AppContext);

    const aiColumns = [{
        title: "",
        key: "ai_gateway_selection",
        width: "15px",
        className: "border-solid border-r-[1px]",
        render: (text, record) => (
            <Radio
                data-testid={`gateway-${record.listener_ip}`}
                checked={record.id === selectedRowKey}
                onChange={() => handleRadioChange(record)}
            />
        ),
    },
    ...aiGatewayColumns
    ]
    const handleRadioChange = (record) => {
        setSelectedSourceGateway(record)
        setSelectedRowKey(record.id);
        setSourceGatewayId(record.id);
        fetchDicomNodes(record.id);
        Store.setDicomNodes([]);
    };

    const fetchDicomNodes = async (gatewayId) => {
        try {
            if (gatewayId == null) return;
            const response = await getDicomNodes(gatewayId);
            const data = response.data || [];
            Store.setDicomNodes(data);
        } catch (error) {
            console.error("Error fetchDicomNodes", error);
        }
    }

    const [isLoadingGateway, setLoadingGateway] = useState(false);
    const fetchGatewayAndStatus = async () => {
        try {
            if (isLoadingGateway) return;
            setLoadingGateway(true);

            const response = await listAllGatewayByUserID();
            if (!response.data) return;

            const gatewayWithStatus = await fetchGatewaysStatusAI(response.data);
            setGatewayData(gatewayWithStatus)

        } catch (e) {
            console.error(e);
        } finally {
            setLoadingGateway(false);
        }
    }

    useEffect(() => {
        fetchGatewayAndStatus();
    }, []);

    useEffect(() => {
        // init auto select first 0 gateway
        if (selectedRowKey === null && gatewayData && gatewayData.length > 0) {
            setSelectedRowKey(gatewayData[0].id);
            setSourceGatewayId(gatewayData[0].id);
            fetchDicomNodes(gatewayData[0].id);
            setSelectedSourceGateway(gatewayData[0]);
        }
    }, [gatewayData])

    useEffect(() => {
        if (!dicomNodes || dicomNodes.length === 0) {
            fetchDicomNodes(sourceGatewayId);
        }
    }, [])

    const innerTablePagination = {
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'],
    };

    const [isCreateDicomNodeModalVisible, setIsCreateDicomNodeModalVisible] = useState(false);
    const onCreateDicomNode = async (data) => {
        try {
            await createAiDicomNode(data);
            fetchDicomNodes(sourceGatewayId);

            message.success("Dicom node create success");
        } catch (error) {
            console.error("Error onCreateDicomNode", error);
            message.error("Failed create dicom node");
        }
    }

    const onCreateDicomNodeCloud = async (gateway_id) => {
        try {
            await createAiDicomNodeCloud(gateway_id);
            fetchDicomNodes(sourceGatewayId);

            message.success("Dicom node create success");
        } catch (error) {
            console.error("Error onCreateDicomNodeCloud", error);
            message.error("Failed create dicom node cloud");
        }
    }


    const deleteDicomNode = async (node_id) => {
        try {
            await deleteDicomAiNode(sourceGatewayId, node_id);
            fetchDicomNodes(sourceGatewayId);
            message.success("Dicom node deleted success");
        } catch (e) {
            console.error(e);
            message.error("Failed delete dicom node");
        }
    }

    const [selectedDicomNode, setSelectedDicomNode] = useState(null);

    const toggleEditModal = () => {
        const data = {
            ...selectedDicomNode,
            gateway_id: sourceGatewayId,
            node_id: selectedDicomNode.id,
        }

        setCurrentModal({
            modal: MODAL_EDIT_DICOM_NODE,
            data: data,
        });
    }

    const dropDownMenu = (data) => {
        const isCloud = data.name === "CLOUD" && 
                        data.ip === null && 
                        data.port === null && 
                        data.aet === null && 
                        data.transferSyntax === null && 
                        data.retrieve === null;

        setSelectedDicomNode(data);

        return <Menu>
            {!isCloud && <Menu.Item key="edit-ai-dicom" onClick={toggleEditModal}>Edit</Menu.Item>}
            <Menu.Item key="delete-ai-dicom">
                <Popconfirm
                    title="Do you want to delete?"
                    okButtonProps={{ className: "bg-red-600" }}
                    onConfirm={() => {
                        deleteDicomNode(data.id);
                    }}
                >
                    Delete
                </Popconfirm>
            </Menu.Item>
        </Menu>
    }

    const destinationDicomNodeColumns = [
        ...fixedDestinationDicomNodesColumns,
        {
            title: "Action",
            dataIndex: "action",
            className: "w-[50px]",
            render: (_, record) => {
                return (
                    <Dropdown dropdownRender={() => {
                        return dropDownMenu(record);
                    }} trigger={['click']}>
                        <Button icon={<EllipsisOutlined />} />
                    </Dropdown>
                )
            }
        },
    ]

    return (
        <div>
            <div>
                {isCreateDicomNodeModalVisible &&
                    <CreateDicomNodeModal onSubmit={(data) => {
                        if (data.type && data.type === "cloud") {
                            onCreateDicomNodeCloud(sourceGatewayId);
                        } else {
                            onCreateDicomNode({
                                ...data,
                                gateway_id: sourceGatewayId,
                            });
                        }
                    }} onClose={() => {
                        setIsCreateDicomNodeModalVisible(!isCreateDicomNodeModalVisible)
                    }} />}

                <GatewaysTable
                    selectedGateway={selectedSourceGateway}
                    gatewayData={gatewayData}
                    columns={aiColumns}
                    pagination={innerTablePagination}
                />
            </div>

            <DicomNodesTable
                dicomNodes={dicomNodes}
                pagination={innerTablePagination}
                selectedGatewayID={sourceGatewayId}
                onCreateDicomNodeClick={() => setIsCreateDicomNodeModalVisible(!isCreateDicomNodeModalVisible)}
                disabledCreateDicom={selectedSourceGateway && selectedSourceGateway.type === "user"}
                columns={destinationDicomNodeColumns}
            />
        </div>

    )
};
