import React, { useState } from "react";
import {
  Modal,
  Form,
  Button,
  Table,
  Spin,
  InputNumber,
  Input,
  Typography,
  Select,
  Popconfirm,
  message
} from "antd";
import {
  retrieveMode,
  transferSyntax,
  mapValueTransferSyntax,
} from "../../pages/constants/dicomNode";
import {
  deleteDicomNode,
  updateDicomNode,
  createDicomNode,
} from "../../apis/node";
import { showSuccess } from "../../libs/message";

const defaultRetrieveNode = retrieveMode[1];
const RetrieveNodeSelection = (
  <Select defaultValue={retrieveMode[1]} placeholder="Select Retrieval Mode">
    {retrieveMode &&
      retrieveMode.map((retrieve) => {
        return <Select.Option value={retrieve}>{retrieve}</Select.Option>;
      })}
  </Select>
);

const defaultTransferSyntax = transferSyntax[1];
const TransferSyntaxNode = (
  <Select defaultValue={defaultTransferSyntax} placeholder="Select transfer syntax">
    {transferSyntax &&
      transferSyntax.map((transferSyntax) => {
        return (
          <Select.Option value={transferSyntax}>
            {mapValueTransferSyntax[transferSyntax]}
          </Select.Option>
        );
      })}
  </Select>
);

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  let inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  if (dataIndex === "transferSyntax") {
    inputNode = TransferSyntaxNode;
  }

  if (dataIndex === "retrieve") {
    inputNode = RetrieveNodeSelection;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item className="max-w-[180px]" name={dataIndex} style={{ margin: 0 }}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const ManageNodeModal = ({ isOpen, data, gateway_id, handleClose }) => {
  const [form] = Form.useForm();

  const deleteRow = async (record) => {
    try {
      const response = await deleteDicomNode(gateway_id, record.id);
      console.log(response);
      showSuccess("Dicom node deleted success");
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    } catch (e) {
      console.error(e);
    }
  };

  const save = async (record) => {
    try {
      const row = await form.validateFields();
      const requestToUpdate = {
        gateway_id,
        node_id: record.id,
        name: row.name,
        ip: row.ip,
        port: row.port,
        aet: row.aet,
        transferSyntax: row.transferSyntax,
        retrieve: row.retrieve,
      };

      const response = await updateDicomNode(requestToUpdate);
      showSuccess("Dicom node updated success");
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    } catch (e) {
      console.error(e);
    }
  };

  const modalFooter = (
    <div>
      <Button key="back" onClick={handleClose}>
        Close
      </Button>
    </div>
  );

  const isEditing = (record) => record.id === editingKey;
  const [editingKey, setEditingKey] = useState("");
  const edit = (record) => {
    form.setFieldsValue(record);
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "text-sm p-2",
      filterSearch: true,
      editable: true,
      onFilter: (value, record) => {
        return record.Tag.indexOf(value) >= 0;
      },
      render: (_, record) => {
        return <p className="w-[100px]">{record.name}</p>;
      },
    },
    {
      title: "IP",
      key: "ip",
      dataIndex: "ip",
      className: "text-sm p-2",
      editable: true,
      render: (_, record) => {
        return <p className="w-[100px]">{`${record.ip}:${record.port}`}</p>;
      },
    },
    {
      title: "AET",
      key: "aet",
      dataIndex: "aet",
      className: "text-sm p-2",
      editable: true,
      render: (_, record) => {
        return <p className="w-[90px]">{record.aet}</p>;
      }
    },
    {
      title: "TransferSyntax",
      key: "transferSyntax",
      dataIndex: "transferSyntax",
      className: "text-sm p-2",
      editable: true,
      render: (value) => {
        return <p className="w-[200px]">{mapValueTransferSyntax[value]}</p>;
      },
    },
    {
      title: "Retrieve",
      key: "retrieve",
      dataIndex: "retrieve",
      className: "text-sm p-2",
      editable: true,
    },
    {
      title: "Port",
      key: "port",
      dataIndex: "port",
      editable: true,
      render: (value, record) => {
        return <p className="w-[50px]">{value}</p>;
      },
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      className: "text-sm p-2",
      render: (value, record) => {
        const editable = isEditing(record);
        return editable ? (
          <p className="w-[150px]">
            <Typography.Link
              onClick={() => save(record, value)}
              style={{ marginRight: 8 }}
            >
              Save
            </Typography.Link>
            <Typography.Text
              className="text-red-500 cursor-pointer"
              style={{ marginRight: 8 }}
            >
              <Popconfirm
                title="Do you want to delete?"
                description={() => {
                  return (
                    <>
                      Are you sure to delete <b>{record.Name}</b>? This node will be deleted permanently.
                    </>
                  );
                }}
                okButtonProps={{ className: "bg-blue-600" }}
                onConfirm={() => deleteRow(record)}
              >
                Delete
              </Popconfirm>
            </Typography.Text>
            <Popconfirm
              okButtonProps={{ className: "bg-blue-600" }}
              title="Do you want to cancel?"
              onConfirm={cancel}
            >
              <a>Cancel</a>
            </Popconfirm>
          </p>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const [isShowAddNewDicomeNode, setIsShowAddNewDicomeNode] = useState(false);
  const [formAddNewDicome] = Form.useForm();
  const [isAddingDicomNode, setAddingNewDicomNode] = useState(false);
  const addNewNode = async () => {
    try {
      if (isAddingDicomNode) return;
      setAddingNewDicomNode(true);
      const formData = await formAddNewDicome.validateFields();
      await createDicomNode({
        ...formData,
        gateway_id: gateway_id,
        port: parseInt(formData.port, 10),
      });

      showSuccess("Dicom node create success");
      setTimeout(() => {
        window.location.reload(true);
        setAddingNewDicomNode(false);
      }, 1000);
    } catch (e) {
      console.error(e);
      message.error('Failed to create / update dicom node');
    }
  };

  return (
    <Modal
      title="Manage Dicom"
      open={true}
      styles={{ "content": { "width": "1200px" } }}
      closable={true}
      className="sm:max-w-7xl md:max-w-7xl"
      footer={modalFooter}
      width={1200}
      onCancel={() => {
        handleClose();
        form.resetFields();
      }}
    >
      <div className="pb-2.5">
        <Button
          onClick={() => {
            formAddNewDicome.resetFields();
            setIsShowAddNewDicomeNode(!isShowAddNewDicomeNode);
          }}
          className="bg-blue-600"
          type="primary"
        >
          Add new DICOM node
        </Button>
        {isShowAddNewDicomeNode && (
          <div className="w-[250px]">
            <Spin spinning={isAddingDicomNode}>
              <Form initialValues={{ transferSyntax: defaultTransferSyntax, retrieve: defaultRetrieveNode }} form={formAddNewDicome} layout="vertical" className="pt-2.5">
                <Form.Item
                  rules={[{ required: true, message: "Please input node name" }]}
                  name="name"
                >
                  <Input placeholder="Name"></Input>
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: "Please input IP" }]}
                  name="ip"
                >
                  <Input placeholder="IP"></Input>
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: "Please input AET" }]}
                  name="aet"
                >
                  <Input placeholder="AET"></Input>
                </Form.Item>
                <Form.Item
                  rules={[
                    { required: true, message: "Please input transferSyntax" },
                  ]}
                  name="transferSyntax"
                >
                  {TransferSyntaxNode}
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: "Please input Retrieve" }]}
                  name="retrieve"
                >
                  {RetrieveNodeSelection}
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: "Please input port" }]}
                  name="port"
                >
                  <Input placeholder="Port" />
                </Form.Item>
              </Form>
            </Spin>

            <Button
              onClick={addNewNode}
              className="bg-blue-600 mr-2"
              type="primary"
            >
              Save
            </Button>

            <Button
              onClick={() => {
                setIsShowAddNewDicomeNode(!isShowAddNewDicomeNode);
              }}
            >
              Cancel
            </Button>
          </div>
        )}
      </div>

      <Form form={form} layout="vertical" name="manageDicomeForm">
        {data && data.length > 0 && (
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            className="w-full"
            dataSource={data}
            columns={mergedColumns}
          />
        )}
      </Form>
    </Modal>
  );
};
