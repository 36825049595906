import React, { useState } from "react";
import { message } from "antd";
import {
  Modal,
  Form,
  Button,
  Table,
  Spin,
  InputNumber,
  Input,
  Typography,
  Popconfirm,
} from "antd";
import {
  updateGateway,
  deleteGateway,
} from "../../../../apis/gateway";
import { restartListenerAi } from "../../../../apis/ai";
import { EditListenerModal } from "./EditAIListenerModal";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  let inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const EditGatewayModal = ({ isOpen, data, handleClose }) => {
  const [form] = Form.useForm();
  const [isShowListenerModal, setIsShowListenerModal] = useState();

  const deleteRow = async (record) => {
    try {
      await deleteGateway(record.id);
      message.success("Successfully deleted gateway");
      setTimeout(() => {
        window.location.reload(true);
      }, 2000);
    } catch (e) {
      console.error(e);
    }
  };

  const save = async (record) => {
    try {
      const row = await form.validateFields();
      if (!row || !row.name || row.name.trim().length === 0) {
        return;
      }
      await updateGateway(record.id, {
        ...row,
      });

      message.success("Updated data complete");
      setTimeout(() => {
        window.location.reload(true);
      }, 2000);
    } catch (e) {
      console.error(e);
    }
  };

  const modalFooter = (
    <div>
      <Button onClick={handleClose}>Close</Button>
    </div>
  );

  const isEditing = (record) => record.id === editingKey;
  const [editingKey, setEditingKey] = useState("");
  const edit = (record) => {
    form.setFieldsValue(record);
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const [selectedGatewayID, setSelectedGatewayID] = useState(0);

  const onHandlerestartListener = async (record) => {
    try {
      message.info("Restarting Listener ...");
      await restartListenerAi(record.id);
      message.success("Listener has successfully restarted ...");
      setTimeout(() => {
        window.location.reload(true);
      }, 2000);
    } catch (e) {
      console.error(e);
    }
  };

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      hidden: true,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "text-sm p-2 max-w-[60px]",
      filterSearch: true,
      editable: true,
      onFilter: (value, record) => {
        return record.Tag.indexOf(value) >= 0;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      className: "text-sm p-2 max-w-[40px]",
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      className: "max-w-[120px] text-sm p-2",
      render: (value, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link style={{ marginRight: 8 }}>
              <Popconfirm
                title="Do you want to update?"
                okButtonProps={{ className: "bg-blue-600" }}
                onConfirm={() => {
                  save(record, value);
                }}
              >
                Save
              </Popconfirm>
            </Typography.Link>
            <Typography.Text
              className="text-red-500 cursor-pointer"
              style={{ marginRight: 8 }}
            >
              <Popconfirm
                title="Do you want to delete?"
                description={() => {
                  return (
                    <>
                      This gateway will be permanently deleted <b>{record.Name}</b>
                    </>
                  );
                }}
                okButtonProps={{ className: "bg-blue-600" }}
                onConfirm={() => deleteRow(record)}
              >
                Delete
              </Popconfirm>
            </Typography.Text>
            <Popconfirm
              okButtonProps={{ className: "bg-blue-600" }}
              title="Do you want to cancel?"
              onConfirm={cancel}
            >
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <div className="flex">
            <Button
              disabled={editingKey !== ""}
              className="hover:underline"
              data-testid="edit-gateway"
              onClick={() => edit(record)}
            >
              Edit
            </Button>
            <Button
              className="ml-2"
              data-testid="edit-listener"
              disabled={editingKey !== ""}
              onClick={() => {
                setSelectedGatewayID(record.id);
                setIsShowListenerModal(!isShowListenerModal);
              }}
            >
              Update Listener
            </Button>
            <Popconfirm
              title="Do you want to restart?"
              description={() => {
                return (
                  <>
                    This server will be restarted <b>{record.name}</b>
                  </>
                );
              }}
              okButtonProps={{ className: "bg-blue-600" }}
              onConfirm={() => {
                onHandlerestartListener(record);
              }}
            >
              <Button
                data-testid="restart-gateway-listener"
                className="ml-2"
                disabled={editingKey !== ""}
              >
                Restart
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const onHandleClose = () => {
    form.resetFields();
    handleClose();
  };

  const onListenerModalClose = () => {
    setIsShowListenerModal(!isShowListenerModal);
  };

  return (
    <Modal
      className=""
      title="Manage Gateway"
      open={isOpen}
      closable={true}
      footer={modalFooter}
      width={1000}
      onCancel={() => {
        onHandleClose();
        form.resetFields();
      }}
    >
      {isShowListenerModal && (
        <EditListenerModal
          handleClose={onListenerModalClose}
          data={data}
          selectedGatewayID={selectedGatewayID}
          isOpen={isShowListenerModal}
        />
      )}
      <Form form={form} layout="vertical" name="manageGatewayForm">
        {!data || (data.length === 0 && <Spin />)}
        {data && data.length > 0 && (
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            className="w-full"
            dataSource={data}
            columns={mergedColumns}
          />
        )}
      </Form>
    </Modal>
  );
};
