import { create } from 'zustand'
import { persist } from 'zustand/middleware';

const createDicomStore = (id) => create(persist(
  (set) => ({
    sourceDicomNodes: null,
    destinationDicomNodes: null,
    destinationDicome: null,
    setDestinationDicome: (destinationDicome) => set({ destinationDicome }),
    sourceDicomeNodeID: null,
    setSourceDicomNodes: (node) => set({ sourceDicomNodes: node }),
    setDestinationDicomNodes: (node) => set({ destinationDicomNodes: node }),
    setSourceDicomeNodeID: (id) => set({ sourceDicomeNodeID: id }),
  }),
  {
    name: `dicomNodeStore-${id}`,
    getStorage: () => sessionStorage,
  }
));

export const DashboardDicomNodeStore = createDicomStore('dashboard-dicom-node');
export const BatchTransferingDicomNodeStore = createDicomStore('batch-transfering-dicom-node');