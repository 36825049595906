const exportedConfig = {
  prod: {
    apiHost: "https://creni.org/api/v1/",
    uploaderApiHost: "https://creni.org/uploader",
    viewerHost: "https://creni.org/",
    sampleExcelLink: "http://192.168.192.16:9999/applications/sample-file.xlsx",
    downloadAppLink: "https://creni.org/download-app/"
  },
  stg: {
    uploaderApiHost: "http://192.168.192.16:7777",
    apiHost: "http://192.168.192.16:4500/v1/",
    downloadAppLink: "http://192.168.192.16:9999/applications/",
    sampleExcelLink: "http://192.168.192.16:9999/applications/sample-file.xlsx",
    viewerHost: "http://192.168.192.16:9999/",
  },
  dev: {
    uploaderApiHost: "http://localhost:7777",
    apiHost: "http://localhost:4500/v1/",
    viewerHost: "http://localhost:8080/",
    sampleExcelLink: "http://192.168.192.16:9999/applications/sample-file.xlsx",
    downloadAppLink: "http://localhost:8080/download-app/"
  },
};

export const appConfig = exportedConfig[process.env["BUILD_MODE"]];
