import React, { useEffect, useState } from "react";
import {
  deleteRequestPermission,
  approveRequestPermission,
} from "../../apis/admin";
import {
  listRequestPermission,
} from "../../apis/admin";
import { message } from "antd";
import { Button, List } from "antd";
const AuthorisedAccess = () => {
  const onApproveRequest = async (id) => {
    try {
      await approveRequestPermission(id);
      message.success("Approve permisson success");
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    } catch (e) {
      console.error(e);
      message.error("Failed to delete permission");
    }
  };

  const onDeleteRequest = async (id) => {
    try {
      await deleteRequestPermission(id);
      message.success("Delete permisson success");
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    } catch (e) {
      console.error(e);
      message.error("Failed to delete permission");
    }
  };

  const [currentListRequestPermission, setListRequestPermission] = useState([]);
  useEffect(() => {
    const fetchPermission = async () => {
      try {
        const resp = await listRequestPermission();
        if (resp && resp.data) {
          setListRequestPermission(resp.data);
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchPermission();
  }, []);

  return (
    <div className="border p-2.5 mt-2.5">
      {currentListRequestPermission.length === 0 && <p className="text-center">No new requests</p>}
      {currentListRequestPermission.length !== 0 && <List
        dataSource={currentListRequestPermission}
        renderItem={(request) => (
          <List.Item
            actions={[
              <Button
                className="bg-blue-500"
                key="approve"
                type="primary"
                onClick={() => onApproveRequest(request.id)}
              >
                Approve
              </Button>,
              <Button
                key="delete"
                type="danger"
                onClick={() => onDeleteRequest(request.id)}
              >
                Delete
              </Button>,
            ]}
          >
            <div className="flex">
              <p>
                User {request.user.email} {"\n"} <br></br>
                {`\nGateway: ${request.gateway.name}, Node: ${request.node.name}`}
              </p>
              <p className="pl-10">
                Permissions:{" "}
                {request.permissions.map((perm) => perm.name).join(", ")}
              </p>
            </div>
          </List.Item>
        )}
      />}

    </div>
  );
};

export default AuthorisedAccess;
