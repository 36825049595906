import { Layout } from "antd";
import SignupForm from "./SignupForm";
import { Typography, message } from "antd";
import { registerAndAuthenticateUser } from "../../apis/apis";
const { Title, Link } = Typography;

export const SignupPage = () => {
  const onFinish = async (values) => {
    console.log("Received values of form: ", values);
    try {
      if (values.confirm_password !== values.password) {
        message.error("Password and confirm password not match");
        return;
      }

      const result = await registerAndAuthenticateUser(values);
      console.log("Logged user ", result)
      if (result) {
        message.success(`User ${values.email} has been created`);
        localStorage.setItem("token", result.access_token);
        window.location.reload(true);
      }
    } catch (e) {
      console.log(e);
      if (e.response) {
        message.error(e.response.data.message);
      }
    }
  };

  return (
    <Layout className="bg-white flex-row">
      <div className="flex flex-col flex-1 p-20">
        <Typography>
          <Title level={2}>Signup</Title>
        </Typography>
        <SignupForm onFinish={onFinish} />
      </div>
      <div className="flex p-20 flex-1">
        <Typography>
          <Title level={2}>Already have a account?</Title>
          <Link href="/dashboard/login">Click here</Link>
        </Typography>
      </div>
    </Layout>
  );
};
