import axios from 'axios';
import { _generateAuthoriseHeader, _generateAuthoriseHeaderForm } from './init'
import { appConfig } from "../config/config";

const serverAPIAxios = axios.create({
    baseURL: appConfig.uploaderApiHost,
});


export const uploadDicomFile = async (formData, signal) => {
    try {
        const response = await serverAPIAxios.post("/v1/upload-dicom/", formData, {
            headers: _generateAuthoriseHeaderForm(),
            signal,
        });
        return response.data;
    } catch (error) {
        console.error("Error upload file:", error);
        throw error;
    }
};

