import { Upload, Button, Typography } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useExcelListKeyStore } from '../../../stores/excel';
import { appConfig } from "../../../config/config";
import * as XLSX from "xlsx";

const { Title } = Typography;

const readExcelFile = (file, onExcelFileReadFinish) => {
  const reader = new FileReader();
  reader.onerror = () => {
    onExcelFileReadFinish([]);
  };

  reader.onload = (event) => {
    try {
      const wb = XLSX.read(event.target.result, {
        type: "binary",
        raw: true,
        cellDates: true,
      });
      const sheets = wb.SheetNames;

      if (sheets.length) {
        const rows = XLSX.utils.sheet_to_json(wb.Sheets[sheets[0]]);
        onExcelFileReadFinish(rows);
        return;
      }
    } catch (error) {
      console.error("Error reading file:", error);
    }
    onExcelFileReadFinish([]);
  };
  reader.readAsBinaryString(file);
};

export const ExcelReader = ({ onExcelFileReadFinish }) => {
  const setFileList = useExcelListKeyStore((state) => state.setFileList);
  const fileList = useExcelListKeyStore((state) => state.fileList);

  const customRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      readExcelFile(file, onExcelFileReadFinish);
      onSuccess("ok");
    }, 0);
  };


  const handleChange = ({ fileList }) => setFileList([...fileList]);

  return (
    <div className="pb-3 mr-10 ml-10 mt-1 flex flex-col">
      <div>
        <Title className="ml-[35%]" level={5}>
          Excel file
        </Title>
        <Upload
          accept=".xlsx, .xls"
          showUploadList={false} // Set to false to hide the upload list
          beforeUpload={() => true}
          fileList={fileList}
          onChange={handleChange}
          customRequest={customRequest}
        >
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </div>

      <a className="pt-2.5 underline" href={appConfig.sampleExcelLink} >Click here to download sample file</a>
      {fileList && fileList.length > 0 && <p>{fileList[0].name}</p>}
    </div>
  );
};
