import { listAllDicomNodeByGatewayID } from "../../../../apis/gateway";
import { getDicomNodeStatus } from "../../../../apis/node";
import { CLOUD_PACS_MODE, GATEWAY_ONLINE_STATUS } from "../../../constants/dicomNode";
import { message } from 'antd'

const fetchDicomCodeStatus = async (data, currentSelectedGatewayId) => {
    const newUpdateDicomNodeStatus = data.map(async (dicomNode) => {
        try {
            const response = await getDicomNodeStatus(
                currentSelectedGatewayId,
                dicomNode.id
            );
            if (!response || !response.data.status) {
                return {
                    ...dicomNode,
                };
            }

            return {
                ...dicomNode,
                status: response.data.status,
            };
        } catch (e) {
            console.log(e);
            return {
                ...dicomNode,
            };
        }
    });

    const allResponse = await Promise.all(newUpdateDicomNodeStatus);
    return allResponse;
}

export const fetchDicomNodes = async ({ currentSelectedGateway }) => {
    if (!currentSelectedGateway) return [];
    if (currentSelectedGateway.id === CLOUD_PACS_MODE) return [];

    try {
        const response = await listAllDicomNodeByGatewayID(currentSelectedGateway.id);
        if (!response.data || response.data.length === 0) {
            return [];
        }
        if (currentSelectedGateway.status !== GATEWAY_ONLINE_STATUS) {
            throw new Error('Gateway is not online');
        }

        const dicomeWithStatus = await fetchDicomCodeStatus(response.data, currentSelectedGateway.id);
        return dicomeWithStatus;
    } catch (e) {
        console.error(e);
        throw e;
    }
};