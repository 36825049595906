import { Table } from "antd";
import { useEffect, useState } from "react";
import { StudiesListForExcel } from "../components/Studies/StudiesListForExcel";
import { findDataFromNode, buildQuery } from "../../apis/node";
import { formatLayout } from '../../libs/date'
import { BatchTransferingStudiesKeystore } from "../../stores/rowsKey";
import { BatchTransferingImageStudiesStore } from "../../stores/studies";
import { ExcelRawDataStore } from "../../stores/excel";
import { v4 as uuidv4 } from 'uuid';

import _ from "lodash";

const columns = [
  {
    title: "Code",
    dataIndex: "Code",
  },
  {
    title: "First Name",
    dataIndex: "First Name",
  },
  {
    title: "Last Name",
    dataIndex: "Last Name",
  },
  {
    title: "Accession Number",
    dataIndex: "Accession Number",
  },
  {
    title: "PatientID",
    dataIndex: "PatientID",
  },
  {
    title: "DOB",
    dataIndex: "DOB",
    render: (_, record) => {
      return <p>{formatLayout(record.DOB) || "N/A"}</p>;
    },
  },
  {
    title: "Modality",
    dataIndex: "Modality",
  },
  {
    title: "Status",
    dataIndex: "Status",
    render: (_, record) => {
      return <p>{record.Status || "Waiting"}</p>;
    },
  },
];

const generateRowKey = (record) => {
  const key =
    _.get(record, "Code") +
    _.get(record, "First Name") +
    _.get(record, "Last Name") +
    _.get(record, "Accession Number") +
    _.get(record, "PatientID") +
    _.get(record, "DOB") +
    _.get(record, "Modality") +
    _.get(record, "Status") +
    new uuidv4();
  return key;
};

export const ExcelList = ({
  sourceGatewayID,
  sourceDicomeNodeID,
  excelRawData
}) => {
  const toggleKeyExpand = BatchTransferingStudiesKeystore((state) => state.toggleKeyExpand)
  const expandedRowKeys = BatchTransferingStudiesKeystore((state) => state.expandedRowKeys)
  const setStudyData = BatchTransferingImageStudiesStore((state) => state.setStudyData)
  const currentStudyData = BatchTransferingImageStudiesStore((state) => state.currentStudyData)
  const tableData = ExcelRawDataStore((state) => state.excelTableData)
  const setExcelTableData = ExcelRawDataStore((state) => state.setExcelTableData)

  const hasSelectedGatewayAndDicomeNodeID =
    sourceGatewayID && sourceDicomeNodeID;

  useEffect(() => {
    if (excelRawData && hasSelectedGatewayAndDicomeNodeID) {
      if (currentStudyData && Object.keys(currentStudyData).length > 0) {
        return;
      }

      const updatedData = excelRawData.map((record) => {
        const key = generateRowKey(record);
        return {
          ...record,
          key,
        };
      });

      setExcelTableData(updatedData);
      updatedData.forEach(async (data) => {
        setTimeout(async () => {
          const firstName = _.get(data, "First Name", "");
          const lastName = _.get(data, "Last Name", "");
          const patientName =  `${firstName} ${lastName}`.trim();


          const patientID = _.get(data, "PatientID", null);
          const modality = _.get(data, "Modality", null);
          const accessionNumber = _.get(data, "Accession Number", null);
          let dob = _.get(data, "DOB", null);
          const code = _.get(data, "Code", null);

          if (dob) {
            dob = formatLayout(dob);
          }

          const queryBuilder = buildQuery({
            patientName,
            patientID,
            modality,
            accessionNumber,
            dob,
            code,
          });

          const studyResponseData = await findDataFromNode(
            sourceGatewayID,
            sourceDicomeNodeID,
            queryBuilder
          );

          if (studyResponseData.data) {
            setStudyData({
              [patientID]: studyResponseData.data,
            });
          }

          toggleKeyExpand(data.key);
        }, 500);
      });
    }
  }, [excelRawData, hasSelectedGatewayAndDicomeNodeID])

  const handleExpand = async (expanded, record) => {
    toggleKeyExpand(record.key);
  };

  useEffect(() => {
    const updateStatusStudyData = () => {
      if (!currentStudyData || Object.keys(currentStudyData).length === 0) {
        return;
      }

      // Turn waiting to OK status
      const newDataWithStatus = tableData.map(obj => {
        const hasStudyData = obj.PatientID && currentStudyData[obj.PatientID] && currentStudyData[obj.PatientID].length !== 0;
        return hasStudyData ? { ...obj, Status: "OK" } : obj;
      });

      setExcelTableData(newDataWithStatus);
    };

    updateStatusStudyData();
  }, [currentStudyData]);


  useEffect(() => {
    // This function updates the expanded row keys if there are any rows with status "OK"
    const updateExpandedRowKeysBasedOnStatus = () => {
      // Find the first row with status "OK" and non-null study data
      const rowToExpand = tableData.find(row => row.Status === "OK" && currentStudyData[row.PatientID] && currentStudyData[row.PatientID].length > 0);
      if (rowToExpand) {
        toggleKeyExpand(rowToExpand.key);
      }
    };

    updateExpandedRowKeysBasedOnStatus();

  }, [tableData]);


  const setSelectedListStudies = BatchTransferingImageStudiesStore((state) => state.setSelectedSeries)
  const [localSelectedStudies, setLocalSelectedSeries] = useState({});
  const onStudiesChange = (studies, key) => {
    localSelectedStudies[key] = studies;
    setLocalSelectedSeries(localSelectedStudies);
    const selectedSeries = Object.values(localSelectedStudies).flat();
    setSelectedListStudies(selectedSeries);
  };

  return (
    <Table
      rowKey="key"
      expandable={{
        expandedRowRender: (record) => {
          if (!currentStudyData[record.PatientID] || currentStudyData[record.PatientID].length === 0) {
            return <></>;
          }

          return (
            <StudiesListForExcel
              data={currentStudyData[record.PatientID]}
              gatewayID={sourceGatewayID}
              nodeID={sourceDicomeNodeID}
              onSelect={onStudiesChange}
              uniqueKey={record.PatientID}
            />
          );
        },
        expandedRowKeys: expandedRowKeys,
        onExpand: handleExpand,
      }}
      className="border-solid border-[1px]"
      columns={columns}
      dataSource={tableData}
      pagination={false}
    />

  );
};
