import { create } from 'zustand'
import { persist } from 'zustand/middleware';
import _ from 'lodash';
const createJobStore = (id) => create(persist(
    (set) => ({
        runningJobs: [],
        addJob: async (jobResponse) => {
            set((state) => ({
                runningJobs: [...state.runningJobs, ...jobResponse] // Adjust according to how jobResponse.data is structured
            }));
        },
        updateRunningJobs: (updates) => set((state) => {
            if (updates.length > 0) {
                const updatedJobs = _.unionBy(
                    state.runningJobs.map((job) => {
                        const update = updates.find((u) => u.id === job.id);
                        return update ? { ...job, status: update.status, name: update.name } : job;
                    }),
                    updates,
                    "id"
                );
                return { runningJobs: updatedJobs };
            }
        })
    }),
    {
        name: `jobStore-${id}`, // unique name
        getStorage: () => sessionStorage, // (optional) by default the 'localStorage' is used
    }
));

export const DashboardJobStore = createJobStore('dashboard-jobstore');
export const BatchTransferingJobStore = createJobStore('batchtrangering-jobstore');