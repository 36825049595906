import React, { useState, useEffect } from "react";
import { Button } from "antd";
import {
  UploadOutlined,
  FileOutlined,
  CloseOutlined,
  CheckOutlined,
  ExclamationOutlined,
  FolderOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import { uploadDicomFile } from "../../../apis/uploader_dicom";

import "./UploadDatasets.scss";
import { List } from "react-virtualized";

const acceptType = ".dcm, .dicom";
const UPLOAD_STATUS = {
  NONE: "none",
  UPLOADING: "uploading",
  DONE: "done",
  ERROR: "error",
};

let count = 0;
let subscribersCancel = [];

const abortController = new AbortController();

const UploadDatasets = (props) => {
  const { uploadInfoModal, location = {}, onClose } = props;
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [itemUploaded, setItemUploaded] = useState(0);
  const [isUploaded, setUploaded] = useState(false);
  const [isFinish, setIsFinish] = useState(false);

  useEffect(() => {
    if (itemUploaded === fileList?.length && fileList?.length > 0) {
      setIsFinish(true);
      setUploading(false);
    }
    // eslint-disable-next-line
  }, [itemUploaded]);

  useEffect(() => {
    return () => {
      count = 0;
      subscribersCancel = [];
    };
  }, []);

  useEffect(() => {
    if (props.onUploading) {
      props.onUploading(uploading);
    }
    // eslint-disable-next-line
  }, [uploading]);

  useEffect(() => {
    const dragBox = document.getElementById("dragBox");
    [
      "drag",
      "dragstart",
      "dragend",
      "dragover",
      "dragenter",
      "dragleave",
      "drop",
    ].forEach(function (event) {
      dragBox.addEventListener(event, function (e) {
        e.preventDefault();
        e.stopPropagation();
      });
    });
    ["dragover", "dragenter"].forEach(function (event) {
      dragBox.addEventListener(event, function () {
        dragBox.classList.add("is-dragover");
      });
    });
    ["dragleave", "dragend", "drop"].forEach(function (event) {
      dragBox.addEventListener(event, function () {
        dragBox.classList.remove("is-dragover");
      });
    });
    // dragBox.addEventListener("drop", function (e) {
    //   addFiles(e.dataTransfer.files, true);
    // });

    function traverseFileTree(item, path) {
      return new Promise((resolve) => {
        if (item.isFile) {
          item.file((file) => {
            file.fullPath = path + file.name; // we also add full path for file here
            resolve([file]);
          });
        } else if (item.isDirectory) {
          let dirReader = item.createReader();
          dirReader.readEntries((entries) => {
            let promises = [];

            for (let i = 0; i < entries.length; i++) {
              promises.push(
                traverseFileTree(entries[i], path + item.name + "/")
              );
            }

            Promise.all(promises).then((fileArrays) => {
              resolve([].concat(...fileArrays));
            });
          });
        }
      });
    }

    dragBox.addEventListener("drop", function (e) {
      e.preventDefault();
      const items = e.dataTransfer.items;
      let promises = [];

      for (let i = 0; i < items.length; i++) {
        let entry = items[i].webkitGetAsEntry();

        if (entry) {
          if (entry.isFile) {
            addFiles(e.dataTransfer.files, true);
          } else if (entry.isDirectory) {
            promises.push(traverseFileTree(entry, ""));
          }
        }
      }

      if (promises.length !== 0) {
        Promise.all(promises).then((fileArrays) => {
          let files = [].concat(...fileArrays);
          addFiles(files);
        });
      }
    });
    // eslint-disable-next-line
  }, []);

  const onCLickDeleteFile = (file, idx) => {
    if (uploading) return;
    let tempFileList = [...fileList];
    tempFileList.splice(idx, 1);
    setFileList([...tempFileList]);
  };

  const [uploadController, setUploadController] = useState(null);
  const handleUploadFile = async () => {
    if (isUploaded || uploading || fileList.length === 0) return;

    // Create an instance of AbortController
    const controller = new AbortController();
    setUploadController(controller);

    setUploaded(true);
    setUploading(true);

    let fileListUpload = [...fileList];

    for (let idx = 0; idx < fileListUpload.length; idx++) {
      const file = fileListUpload[idx];
      fileListUpload[idx].status = UPLOAD_STATUS.UPLOADING;
      setFileList([...fileListUpload]);
      const formData = new FormData();
      formData.append("dicom", file.originFileObj);
      try {
        // signal: controller.signal, // Pass the signal to Axios
        await uploadDicomFile(formData, controller.signal);
        fileListUpload[idx].status = UPLOAD_STATUS.DONE;
        setFileList([...fileListUpload]);
        count++;
        setItemUploaded(count);
      } catch (error) {
        fileListUpload[idx].status = UPLOAD_STATUS.ERROR;
        if (axios.isCancel(error)) {
          fileListUpload[idx].error_msg = "cancelled upload"
        } else {
          fileListUpload[idx].error_msg = error?.response?.data || "";
        }
        setFileList([...fileListUpload]);
        count++;
        setItemUploaded(count);
      }
    }
  };

  const convertArrToCSV = (data = []) => {
    if (!data || !data.length) return;
    let result = "";
    result += `File name, Type, Error message\n`;
    data.forEach((item) => {
      result += `${item?.name || ""}, ${item?.status || ""}, ${
        JSON.stringify(item?.error_msg) || ""
      }\n`;
    });

    return result;
  };

  const handleExportLog = () => {
    let csv = convertArrToCSV(fileList);
    if (!csv) return;

    const filename = `${moment().format("YYYY_MM_DD")}_upload_log.csv`;
    if (!csv.match(/^data:text\/csv/i)) {
      csv = "data:text/csv;charset=utf-8," + csv;
    }

    const data = encodeURI(csv);
    const link = document.createElement("a");
    link.setAttribute("href", data);
    link.setAttribute("download", filename);
    link.click();
  };

  const addFiles = (files = [], isSelectFile) => {
    const filesTemp = [];
    const filesArray = Array.from(files);
    filesArray.forEach((file, idx) => {
      filesTemp.push({
        fileId: idx,
        name: file.name,
        originFileObj: file,
      });
    });
    if (isSelectFile) {
      setFileList([...fileList, ...filesTemp]);
    } else {
      setFileList(filesTemp);
    }
  };

  const onChangeFile = (event, isSelectFile) => {
    if (!event?.target?.files?.length) return;
    addFiles(event.target.files, isSelectFile);
  };

  const onSelectFile = (isSelectFolder) => {
    if (isSelectFolder) {
      document.getElementById("folderId").click();
    } else {
      document.getElementById("fileId").click();
    }
  };

  const rowRenderer = ({ key, index, isScrolling, isVisible, style }) => {
    const file = fileList[index];
    return (
      <div key={key} style={{ ...style, padding: "0 5px" }}>
        <div className="file-item">
          <span className="file-icon">
            <FileOutlined />
          </span>
          <span className="file-name">{file.name}</span>
          <span
            className={`file-status ${file.status}`}
            onClick={() => onCLickDeleteFile(file, index)}
          >
            {(!file.status || file.status === UPLOAD_STATUS.NONE) && (
              <CloseOutlined />
            )}
            {file.status === UPLOAD_STATUS.UPLOADING && (
              <LoadingOutlined className="ic-uploading" />
            )}
            {file.status === UPLOAD_STATUS.DONE && <CheckOutlined />}
            {file.status === UPLOAD_STATUS.ERROR && <ExclamationOutlined />}
          </span>
        </div>
      </div>
    );
  };


  const handleCancelUpload = () => {
    if (uploadController) {
      uploadController.abort(); // Cancel the upload
    }
  };

  return (
    <div className="import-data-content">
      <div className="header-modal">
        <span className="header-title">Import data</span>
        {isUploaded && (
          <span className="uploaded-info">{`(${itemUploaded}/${fileList.length} files)`}</span>
        )}
      </div>
      <div className="upload-container">
        <input
          className="input-file"
          type="file"
          id="folderId"
          webkitdirectory="true"
          mozdirectory="true"
          directory="true"
          onChange={(evt) => onChangeFile(evt)}
          onClick={(event) => (event.target.value = null)}
        />
        <input
          className="input-file"
          type="file"
          multiple
          onChange={(evt) => onChangeFile(evt, true)}
          id="fileId"
          accept={acceptType}
          onClick={(event) => (event.target.value = null)}
        />
        <div
          className={`upload-drag-box ${
            fileList.length > 0 ? "hide-drag-box" : ""
          }`}
        >
          <div className="drag-box" id="dragBox" onClick={() => onSelectFile()}>
            <div className="drag-content">
              <p className="ant-upload-drag-icon">
                <UploadOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from
                uploading company data or other band files
              </p>
            </div>
          </div>
          {fileList.length > 0 && (
            <div className="file-list">
              <List
                width={580}
                height={400}
                rowCount={fileList.length}
                rowHeight={43}
                rowRenderer={rowRenderer}
              />
            </div>
          )}
        </div>
        <div className="btn-action">
          {!isUploaded && (
            <>
              <Button
                icon={<FolderOutlined />}
                ghost
                disabled={uploading}
                className="btn btn-select-file"
                onClick={() => onSelectFile(true)}
              >
                Select Folder
              </Button>
              <Button
                icon={<UploadOutlined />}
                ghost
                disabled={uploading}
                className="btn btn-select-file"
                onClick={() => onSelectFile()}
              >
                Select File
              </Button>
              <Button
                type="primary"
                loading={uploading}
                onClick={handleUploadFile}
                className="btn btn-primary bg-blue-500"
              >
                Start Upload
              </Button>
            </>
          )}

          {isUploaded && isFinish && (
            <>
              <Button
                type="primary"
                className="btn btn-primary btn-export-log bg-blue-500"
                onClick={handleExportLog}
              >
                Export logs
              </Button>
              <Button ghost type="primary" className="btn" onClick={onClose}>
                Close
              </Button>
            </>
          )}

          {uploading && (
            <Button onClick={handleCancelUpload} type="primary" className="btn">
              Cancel Uploading
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadDatasets;
