export const IMPLICIT_VR_ENDIAN = "1.2.840.10008.1.2";
export const EXPLICIT_VR_LITTLE_ENDIAN = "1.2.840.10008.1.2.1";
export const DEFLATED_EXPLICIT_VR_LITTLE_ENDIAN = "1.2.840.10008.1.2.1.99";
export const EXPLICIT_VR_BIG_ENDIAN = "1.2.840.10008.1.2.2";

export const RETRIEVE_CGET = "c-get";
export const RETRIEVE_CMOVE = "c-move";

export const transferSyntax = [
  IMPLICIT_VR_ENDIAN,
  EXPLICIT_VR_LITTLE_ENDIAN,
  DEFLATED_EXPLICIT_VR_LITTLE_ENDIAN,
  EXPLICIT_VR_BIG_ENDIAN,
];

export const mapValueTransferSyntax = {
  [IMPLICIT_VR_ENDIAN]: "IMPLICIT_VR_ENDIAN",
  [EXPLICIT_VR_LITTLE_ENDIAN]: "EXPLICIT_VR_LITTLE_ENDIAN",
  [DEFLATED_EXPLICIT_VR_LITTLE_ENDIAN]: "DEFLATED_EXPLICIT_VR_LITTLE_ENDIAN",
  [EXPLICIT_VR_BIG_ENDIAN]: "EXPLICIT_VR_BIG_ENDIAN",
};

export const retrieveMode = [RETRIEVE_CGET, RETRIEVE_CMOVE];

export const CLOUD_PACS_MODE = "cloud-pacs";

export const SEARCH_OPTIONS_EMAIL = "email";
export const SEARCH_OPTIONS_NAME = "name";

export const GATEWAY_ONLINE_STATUS = "gateway-online";
export const GATEWAY_OFFLINE_STATUS = "gateway-offline";

export const QUERY_TYPE_SERIES = 'SERIES'
export const QUERY_TYPE_STUDY = 'STUDY'