import React, { useEffect, useState } from "react";
import { Modal, Form, Select, Button, Spin } from "antd";
import { listPermissionOptions } from "../../../apis/admin"; // Assuming this API call returns accessibility options as well

export const EditDicomNodeModal = ({ data, onClose, onSubmit }) => {
  const [form] = Form.useForm();
  const [accessibilityOptions, setAccessibilityOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAccessibilityOptions = async () => {
      setLoading(true);
      try {
        const response = await listPermissionOptions(); // Assuming this fetches accessibility options
        setAccessibilityOptions(response.accessibility); // Adjust according to actual response structure
      } catch (error) {
        console.error("Failed to fetch accessibility options:", error);
      }
      setLoading(false);
    };

    fetchAccessibilityOptions();
  }, []);

  const onModalClose = () => {
    if (onClose) onClose();
  };

  const onFinish = (values) => {
    const { accessibility_id } = values;
    const updatedData = {
      ...data,
      accessibility_id,
    };

    if (onSubmit) {
      return onSubmit(updatedData);
    }
  };

  const selectOptionsValue = accessibilityOptions.map((data) => {
    return {
      label: data.name,
      value: data.id,
    };
  });

  useEffect(() => {
    if (accessibilityOptions.length > 0 && data.accessibility_id) {
      form.setFieldsValue({
        accessibility_id: data.accessibility_id,
      });
    }
  }, [accessibilityOptions, data.accessibility_id, form]);

  return (
    <Modal
      title="Edit Gateway Accessibility"
      open={true}
      onCancel={onModalClose}
      footer={[
        <Button key="back" onClick={onModalClose}>
          Cancel
        </Button>,
        <Button
          className="bg-blue-500"
          key="submit"
          type="primary"
          onClick={() => form.submit()}
        >
          Save Changes
        </Button>,
      ]}
      className="sm:max-w-7xl md:max-w-7xl"
      width={800}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          name="editAccessModal"
        >
          <Form.Item
            name="accessibility_id"
            label="Select Gateway Accessibility"
            rules={[
              {
                required: true,
                message: "Please select an accessibility option!",
              },
            ]}
          >
            <Select options={selectOptionsValue} placeholder="Select accessibility" />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};
