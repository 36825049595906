import React, { useState } from 'react';
import { Modal } from 'antd';
import UploadDatasets from './index';

const ImportDataModal = (props) => {
  const { visible = true, onClose } = props;
  const [processing, setProcessing] = useState(false);

  const handleClose = () => {
    if (processing) return;
    if (onClose) onClose();
  };

  return (
    <Modal
      wrapClassName={`fixed-upload-modal`}
      visible={visible}
      className="common-modal import-data-modal"
      width={750}
      footer={null}
      onCancel={handleClose}
    >
      <UploadDatasets
        onUploading={(isUploading) => {
          setProcessing(isUploading);
        }}
        onClose={handleClose}
      />
    </Modal>
  );
};

export default ImportDataModal;