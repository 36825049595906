import React, { useState } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Input, Radio, DatePicker, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ModalityOptions, FilterDateOptions, StudyQueryType } from '../../pages/constants/filter';
import { calculateDateRange } from '../../libs/date'

const { RangePicker } = DatePicker
const SearchFilters = ({ onChange, isLoading = false, label }) => {
  const [isExpandAdvanceOption, setAdvanceExpandOption] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedPatientOption, setSelectedPatientOption] = useState(StudyQueryType[0].value);
  const [selectedModality, setSelectedModality] = useState(ModalityOptions[0].value);
  const [selectedDateOption, setSelectedDateOption] = useState(FilterDateOptions[0].value);
  const [dateValue, setDateValue] = useState(null);
  const [rangeDateValue, setRangeDateValue] = useState([]);

  const handleExpandAdvanceOption = () => {
    setAdvanceExpandOption(!isExpandAdvanceOption);
  };

  const handleSearch = () => {
    const filter = {}
    if (selectedPatientOption && selectedPatientOption.length > 0) {
      if (selectedPatientOption === 'birthday') {
        filter[selectedPatientOption] = dateValue
      } else {
        filter[selectedPatientOption] = searchQuery
      }
    }
    if (selectedModality && selectedModality.length > 0) {
      delete filter.modality;
      if (selectedModality !== 'any') {
        filter.modality = selectedModality
      }
    }

    if (selectedDateOption && selectedDateOption.length > 0) {
      delete filter.studyDate;
      delete filter.studyDateRange;

      if (selectedDateOption === 'on') {
        filter.studyDate = dateValue
      } else if (selectedDateOption === 'between') {
        filter.studyDateRange = rangeDateValue
      } else if (selectedDateOption !== 'any') {
        const dates = calculateDateRange(selectedDateOption);
        filter.studyDateRange = [dates.startDate, dates.endDate]
      }
    }

    if (onChange) onChange(filter);
  };

  const isDisabledOnDateChange = selectedDateOption !== 'on';
  const isDisabledBetweenChange = selectedDateOption !== 'between';
  const isDisableBirthdayChange = selectedPatientOption !== 'birthday';
  const searchPlaceHolder = `Search by ${StudyQueryType.filter((studyQuery) => studyQuery.value === selectedPatientOption)[0].label}`
  return (
    <div className='flex flex-col'>
      <div className='flex gap-5'>
        {label && <p className='mt-1'>{label}</p>}
        <Input
          placeholder={searchPlaceHolder}
          prefix={<SearchOutlined />}
          className="mb-4 w-[500px]"
          value={searchQuery}
          onPressEnter={handleSearch}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <div className='block'>
          <Button
            iconPosition="end" icon={isExpandAdvanceOption ? <DownOutlined /> : <UpOutlined />}
            onClick={handleExpandAdvanceOption} >
            Advanced
          </Button>
        </div>
        <Button loading={isLoading} className='bg-[#1da1f2]' type='primary' onClick={handleSearch}>
          Search
        </Button>
      </div>
      {
        isExpandAdvanceOption && (
          <div className='flex options p-3 bg-slate-100 rounded border-solid'>
            <div className='patientOption w-[400px] mr-5 bg-white p-2 border-solid rounded'>
              <Radio.Group className='patient-options' value={selectedPatientOption} onChange={(e) => setSelectedPatientOption(e.target.value)}>
                {/* Replace StudyQueryType and ModalityOptions with actual options */}
                {StudyQueryType.map((option) => {
                  if (option.value === 'birthday') {
                    return <Radio value={option.value} key={option.value}>
                      <div className='flex'>
                        <p className='pr-3'>Birth day</p>
                        <DatePicker disabled={isDisableBirthdayChange} onChange={(date, dateString) => setDateValue(dateString)} />
                      </div>
                    </Radio>
                  }

                  return <Radio value={option.value} key={option.value}>{option.label}</Radio>
                })}

              </Radio.Group>
            </div>

            <div className='modality mr-5 bg-white p-2 border-solid rounded'>
              <Radio.Group className='w-[200px] modality-options' value={selectedModality} onChange={(e) => setSelectedModality(e.target.value)}>
                {ModalityOptions.map((option) => {
                  return <Radio value={option.value} key={option.value}>{option.label}</Radio>
                })}
              </Radio.Group>
            </div>

            <div className='modality w-[450px] bg-white p-2 border-solid rounded'>
              <Radio.Group className='filter-date-options' value={selectedDateOption} onChange={(e) => setSelectedDateOption(e.target.value)}>
                {FilterDateOptions.filter((option) => option.value !== 'on' && option.value !== 'between').map((option) => {
                  return <Radio value={option.value} key={option.value}>{option.label}</Radio>
                })}

                <div className='flex flex-row'>
                  {FilterDateOptions.map((option) => {
                    if (option.value === 'on') {
                      return (
                        <div className='flex flex-col w-[150px]' key="on">
                          <Radio value={option.value}>{option.label}</Radio>
                          <DatePicker allowClear={false} disabled={isDisabledOnDateChange} onChange={(date, dateString) => setDateValue(dateString)} />
                        </div>
                      )
                    }

                    if (option.value === 'between') {
                      return (
                        <div className='flex flex-col w-[250px] ml-2' key="between">
                          <Radio value={option.value}>{option.label}</Radio>
                          <RangePicker allowClear={false} disabled={isDisabledBetweenChange} onChange={(dates, dateStrings) => setRangeDateValue(dateStrings)} />
                        </div>
                      )
                    }
                  })}
                </div>

              </Radio.Group>
            </div>
          </div>
        )
      }
    </div >
  );
};

export default SearchFilters;
