import {
    retrieveMode,
    transferSyntax,
    mapValueTransferSyntax,
} from "../../pages/constants/dicomNode";
import { Select } from 'antd'

export const DefaultRetrieveNode = retrieveMode[1];
export const RetrieveNodeSelection = ({ isDisabled }) => (
    <Select disabled={isDisabled} defaultValue={retrieveMode[1]} placeholder="Select Retrieval Mode">
        {retrieveMode &&
            retrieveMode.map((retrieve) => {
                return <Select.Option key={retrieve} value={retrieve}>{retrieve}</Select.Option>;
            })}
    </Select>
);

export const DefaultTransferSyntax = transferSyntax[1];
export const TransferSyntaxNode = ({ isDisabled }) => (
    <Select disabled={isDisabled} defaultValue={DefaultTransferSyntax} placeholder="Select transfer syntax">
        {transferSyntax &&
            transferSyntax.map((transferSyntax) => {
                return (
                    <Select.Option key={transferSyntax} value={transferSyntax}>
                        {mapValueTransferSyntax[transferSyntax]}
                    </Select.Option>
                );
            })}
    </Select>
);
