import { DatePicker, Radio, Input, Button, Spin, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useState } from "react";
import { parseDate } from "chrono-node";
import { formatLayout } from '../../../libs/date'
import { ModalityOptions } from '../../constants/filter'
const { RangePicker } = DatePicker;

const dateOptions = [
  { label: "Any date", value: "any" },
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Last 2 days", value: "last2Days" },
  { label: "Last 7 days", value: "last7Days" },
  { label: "Last 30 min", value: "last30min" },
  { label: "Last 1 hour", value: "last1hour" },
  { label: "Last 2 hours", value: "last2hours" },
  { label: "Last 3 hours", value: "last3hours" },
  { label: "Last 6 hours", value: "last6hours" },
  { label: "Last 8 hours", value: "last8hours" },
  { label: "Last 12 hours", value: "last12hours" },
  { label: "Last 24 hours", value: "last24hours" },
  { label: "On", value: "on" },
  { label: "Between", value: "between" },
];

const convertDatetimeToLayout = (value) => {
  if (["on", "any", "between"].includes(value)) {
    return value;
  }

  const parsedDate = parseDate(value);
  const formattedDate = formatLayout(parsedDate);

  return formattedDate;
};

export const OptionsFilterTable = ({ onOptionUpdate }) => {
  const [checkedModalities, setCheckedModalities] = useState({});
  const [_selectedDate, setSelectedDate] = useState(null);
  const [_selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [optionsUpdateData, setOptionUpdateData] = useState({});

  const handleDateChange = (date, dateString) => {
    setSelectedDate(date);
    setOptionUpdateData({
      ...optionsUpdateData,
      selectedDate: dateString,
    });
    if (onOptionUpdate) {
      onOptionUpdate({
        ...optionsUpdateData,
        selectedDate: formatLayout(dateString),
      });
    }
  };

  const handleRangeChange = (dates, dateStrings) => {
    setSelectedDateRange(dates);
    const convertedLayout = `${formatLayout(dateStrings[0])}-${formatLayout(
      dateStrings[1]
    )}`;
    setOptionUpdateData({
      ...optionsUpdateData,
      selectedDate: convertedLayout,
    });

    if (onOptionUpdate) {
      onOptionUpdate({
        ...optionsUpdateData,
        selectedDate: convertedLayout,
      });
    }
  };

  const handleModalityUpdate = (modality) => {
    setCheckedModalities(modality.target.value);
    setOptionUpdateData({
      ...optionsUpdateData,
      modality: modality.target.value,
    });

    if (onOptionUpdate) {
      onOptionUpdate({
        ...optionsUpdateData,
        modality: modality.target.value,
      });
    }
  };

  const [dateOption, setDateOption] = useState("any");
  const onHandleDateOptions = (dateOption) => {
    setDateOption(dateOption.target.value);
    let selectedDate = "";
    if (dateOption.target.value !== "any") {
      selectedDate =
        convertDatetimeToLayout(dateOption.target.value) +
        "-" +
        convertDatetimeToLayout("now");
    }

    setOptionUpdateData({
      ...optionsUpdateData,
      selectedDate,
    });

    if (onOptionUpdate) {
      onOptionUpdate({
        ...optionsUpdateData,
        selectedDate,
      });
    }
  };

  const isEnableDatePicker = dateOption === "on";
  const isBetweenDateRangeEnable = dateOption === "between";

  return (
    <div className="flex w-[350px] border-solid border-l-[1px] pl-5 ml-11">
      <div className="w-[300px] ">
        <div className="flex">
          <div className="w-11/12">
            <Radio.Group
              className="block"
              options={dateOptions}
              onChange={onHandleDateOptions}
              value={dateOption}
            />
          </div>

          <div className="w-full">
            <Radio.Group
              className="grid grid-cols-2"
              options={ModalityOptions}
              value={checkedModalities}
              onChange={handleModalityUpdate}
            />
          </div>
        </div>
        <div className="w-[220px] mt-5">
          <DatePicker
            disabled={!isEnableDatePicker}
            onChange={handleDateChange}
            className="w-full"
          />
          <RangePicker
            disabled={!isBetweenDateRangeEnable}
            onChange={handleRangeChange}
            className="w-full mt-2"
          />
        </div>
      </div>
    </div>
  );
};

export const SearchFilter = ({
  label,
  isLoading,
  searchOptions = [],
  onSearchClick,
  onSearchTypeChange,
}) => {
  const [searchKeyWordResult, setSearchKeywordResult] = useState("");

  const onSearchCallBack = (e) => {
    setSearchKeywordResult(e.target.value);
  };

  const options = searchOptions.map(({ value, label }) => {
    return { value, label };
  });

  return (
    <div className="flex justify-between items-center mb-4">
      <p>{label}</p>
      <div className="flex-grow px-4">
        <Input
          className="w-[550px]"
          value={searchKeyWordResult}
          placeholder="Search..."
          data-testid="search-keyword-input"
          onChange={onSearchCallBack}
          onPressEnter={() => {
            if (searchKeyWordResult && searchKeyWordResult.length > 0) {
              onSearchClick(searchKeyWordResult);
            }
          }}
          prefix={<SearchOutlined />}
        />
        {options && options.length !== 0 && (
          <Select
            className="ml-2"
            onChange={onSearchTypeChange}
            defaultValue={searchOptions[0]}
            style={{ width: 120 }}
            options={options}
          />
        )}
        <Button
          onClick={() => {
            onSearchClick(searchKeyWordResult);
          }}
          className="ml-5"
          type="default"
          data-testid="search-dicom-image-button"
        >
          {isLoading && <Spin />}
          {!isLoading && "Search"}
        </Button>
      </div>
    </div>
  );
};
