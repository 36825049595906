
export const ModalityOptions = [
    { label: "Any", value: "any" },
    { label: "OT", value: "OT" },
    { label: "NM", value: "NM" },
    { label: "CR", value: "CR" },
    { label: "DR", value: "DR" },
    { label: "US", value: "US" },
    { label: "MG", value: "MG" },
    { label: "ES", value: "ES" },
    { label: "AU", value: "AU" },
    { label: "RF", value: "RF" },
    { label: "SR", value: "SR" },
    { label: "RG", value: "RG" },
    { label: "DX", value: "DX" },
    { label: "CT", value: "CT" },
    { label: "XC", value: "XC" },
    { label: "MR", value: "MR" },
    { label: "XA", value: "XA" },
    { label: "PT", value: "PT" },
];

export const FilterDateOptions = [
    { label: "Any date", value: "any" },
    { label: "Last 1 hour", value: "last1hour" },
    { label: "Today", value: "today" },
    { label: "Last 2 hours", value: "last2hours" },
    { label: "Yesterday", value: "yesterday" },
    { label: "Last 8 hours", value: "last8hours" },
    { label: "Last 30 min", value: "last30min" },
    { label: "Last 24 hours", value: "last24hours" },
    { label: "Last 2 days", value: "last2Days" },
    { label: "Last 7 days", value: "last7Days" },
    { label: "On", value: "on" },
    { label: "Between", value: "between" },
];

export const StudyQueryType = [
    { label: "Patient Name", value: "patientName" },
    { label: "Patient ID", value: "patientID" },
    { label: "Accession Number", value: "accessionNumber" },
    { label: "Study Description", value: "studyDescription" },
    { label: "Referring Physician Name", value: "referringPhysicianName" },
    { label: "Birth day", value: "birthday" },
]