import { serverAPIAxios, _generateAuthoriseHeader } from './init'
import { getTokenData } from "../libs/jwt";

export const getJobsDetail = async (job_id) => {
  try {
    const response = await serverAPIAxios.get(`/job/${job_id}`, {
      headers: _generateAuthoriseHeader(),
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const retrieveToServer = async (data) => {
  try {
    const response = await serverAPIAxios.post(`/job/retrieveToServer`, data, {
      headers: _generateAuthoriseHeader(),
    });

    return response.data;
  } catch (error) {
    console.error("Error retrieveToServer", error);
    throw error;
  }
};

export const retrieveToGateway = async (data) => {
  try {
    const response = await serverAPIAxios.post(`/job/retrieveToGateway`, data, {
      headers: _generateAuthoriseHeader(),
    });

    return response.data;
  } catch (error) {
    console.error("Error retrieveToGateway", error);
    throw error;
  }
};

export const sendToCloudPacs = async (data) => {
  try {
    const auth0_user_id = getTokenData().user_id;
    const response = await serverAPIAxios.post(`/job/images/cloud-pacs`, { ...data, user_id: auth0_user_id }, {
      headers: _generateAuthoriseHeader(),
    });

    return response.data;
  } catch (error) {
    console.error("Error sendToCloudPacs", error);
    throw error;
  }
};

export const listJobs = async () => {
  try {
    const response = await serverAPIAxios.get(`/job`, {
      headers: _generateAuthoriseHeader(),
    });

    return response.data;
  } catch (error) {
    console.error("Error listJobs", error);
    throw error;
  }
};

export const listRunningJobs = async () => {
  try {
    const response = await serverAPIAxios.post(`/job/jobs-running`, {}, {
      headers: _generateAuthoriseHeader(),
    });

    return response.data;
  } catch (error) {
    console.error("Error listRunningJobs", error);
    throw error;
  }
};

export const cancelRunningJobs = async (gateway_id, job_id) => {
  try {
    const response = await serverAPIAxios.post(`/job/cancel/${gateway_id}/${job_id}`, {}, {
      headers: _generateAuthoriseHeader(),
    });

    return response.data;
  } catch (error) {
    console.error("Error cancelRunningJobs", error);
    throw error;
  }
};

export const cancelRunningJobByGatewayId = async (gateway_id) => {
  try {
    const response = await serverAPIAxios.post(`/gateway/cancel/${gateway_id}`, {}, {
      headers: _generateAuthoriseHeader(),
    });

    return response.data;
  } catch (error) {
    console.error("Error cancelRunningJobByGatewayId", error);
    throw error;
  }
};
