import { Layout } from "antd";
import LoginForm from "./LoginForm";
import { Typography } from "antd";
const { Title, Link } = Typography;

export const LoginPage = () => {
  return (
    <Layout className="bg-white flex-row">
      <div className="flex flex-col flex-1 p-20">
        <Typography>
          <Title level={2}>Login</Title>
        </Typography>
        <LoginForm />
      </div>
      <div className="flex p-20 flex-1">
        <Typography>
          <Title level={2}>Register</Title>
          <Link href="/dashboard/signup">Register here</Link>
        </Typography>
      </div>
    </Layout>
  );
};
