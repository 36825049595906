import { Table, Button, Input, Tooltip, Select } from "antd";
import { getListOutputFilesGatewayId } from '../../../apis/ai'
import { AIInboundOutboundStore } from '../../../stores/ai'
import { DashboardAIGatewayStore } from '../../../stores/gateway';
import { useEffect, useState } from "react";
import _ from 'lodash'
const { Search } = Input;

const fixedColumns = [
    {
        title: "File Name",
        dataIndex: "fileName",
        className: "w-[150px]",
        render: (_, record) => {
            return (
                <Tooltip key={record.id} placement="left" title={record.name}>
                    <p className='truncate w-[400px]'>{record.name}</p>
                </Tooltip>
            )
        },
    },
    {
        title: "Gateway",
        dataIndex: "gateway",
        className: "w-[150px]",
        render: (text, record) => {
            return <p key={record.id}>{_.get(record, 'gateway.name', 'N/A')}</p>;
        },
    },
    {
        title: "Target",
        className: "w-[100px]",
        render: (text, record) => {
            return <p key={record.id}>{record.target}</p>;
        },
    },
    {
        title: "Watch",
        dataIndex: "watch",
        className: "w-[150px]",
        render: (text, record) => {
            return <p key={record.id}>{record.watch}</p>;
        },
    },
    {
        title: "Status",
        dataIndex: "status",
        className: "w-[150px]",
        render: (text, record) => {
            return <p key={record.id}>{record.status}</p>;
        },
    },
    {
        title: "Date-time",
        dataIndex: "date-time",
        className: "w-[100px]",
        render: (text, record) => {
            return <p key={record.id}>{record.createdAt}</p>;
        },
    }
]

export const Outbound = ({ onViewLog }) => {
    const columns = [
        ...fixedColumns,
        {
            title: "View logs",
            dataIndex: "viewLog",
            className: "w-[150px]",
            render: (text, record) => {
                return <Button
                    onClick={() => onViewLog(record.message)}
                    className="bg-blue-500 hover:bg-blue-700"
                    type="primary">View log
                </Button>
            },
        },
    ];

    const [searchTerm, setSearchTerm] = useState("");
    const loadedGatewayData = DashboardAIGatewayStore((state) => state.gatewayData)
    const Store = AIInboundOutboundStore((state) => state);
    const data = Store.getOutboundList();
    const selectedGatewayId = Store.getSelectedOutboundGatewayId();

    const fetchData = async () => {
        try {
            if (selectedGatewayId === null) return;
            const response = await getListOutputFilesGatewayId(selectedGatewayId);
            const data = response.data.response || [];
            Store.setOutboundList(data);
        } catch (error) {
            console.error("Error fetchData", error);
        }
    }

    useEffect(() => {
        if (!data || data.length === 0) {
            fetchData();
        }
    }, [selectedGatewayId]);

    const innerTablePagination = {
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'],
    };


    const handleChange = (gatewayId) => {
        Store.setOutboundList([]);
        Store.setSelectedOutboundGatewayId(gatewayId);
    };

    useEffect(() => {
        // init auto select first 0
        if (selectedGatewayId === null && loadedGatewayData && loadedGatewayData.length > 0) {
            Store.setSelectedOutboundGatewayId(loadedGatewayData[0].id);
        }
    }, [loadedGatewayData])


    const filteredData = data.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div>
            <div className="flex">
                <Search
                    placeholder="Search filename"
                    className="mr-2.5"
                    onSearch={value => setSearchTerm(value)}
                    style={{ width: 400 }}
                />
                <Select
                    showSearch
                    className="mb-2.5"
                    defaultValue={loadedGatewayData && loadedGatewayData.length > 0 ? loadedGatewayData[0].id : null}
                    onChange={handleChange}
                    placeholder="Select a gateway"
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={loadedGatewayData.map((gateway) => {
                        return {
                            label: gateway.name,
                            value: gateway.id,
                            key: gateway.id,
                        }
                    })}
                />
            </div>

            <Table
                columns={columns}
                dataSource={filteredData}
                pagination={innerTablePagination}
            />
        </div>

    );
};
