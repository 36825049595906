import { Layout } from "antd";
import NavLogged from "../components/Navigations/NavLogged";
import Footer from "../components/Footer/Footer";
import { useState } from "react";
import { ChangeInformationModal } from "./modal/ChangeInformationModal"
import { ChangePasswordModal } from "./modal/ChangePasswordModal"
import { RequestAccessModal } from "./modal/RequestAccess";
import { EditDicomNodeModal } from "./modal/EditDicomNode";
import { EditGatewayModal } from "./modal/EditGateway/EditGatewayModal";
import { ManageNodeModal } from "./modal/ManageNode";
import { MODAL_RAISE_ACCESS, MODAL_EDIT_DICOM_NODE, MODAL_EDIT_GATEWAY, MODAL_MANGE_DICOM_NODE } from '../../src/pages/constants/modals';
import { updateUserPassword, updateUserInformation } from '../apis/apis'
import { getTokenData } from "../libs/jwt";
import {
  requestPermission,
} from "../apis/admin";
import { message } from "antd";
import { useContext } from 'react';
import { AppContext } from '../libs/context';

const DashboardLayout = ({ children, onClose }) => {
  const tokenData = getTokenData();
  const { currentModal, setCurrentModal } = useContext(AppContext);

  const onRequestSubmit = async (data) => {
    try {
      await requestPermission({
        permission_ids: data.permission_ids,
        node_id: data.node_id,
        gateway_id: data.gateway_id,
      });
      message.success("Request permission success");
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    } catch (e) {
      message.error("Failed to request permission check error");
      console.error(e);
    }
  };

  const [isPasswordShowModal, setIsPasswordModalVisible] = useState(false);
  const [isChangePersonalModal, setIsPersonalModalVisible] = useState(false);

  const onChangePersonalInformationClick = () => {
    setIsPersonalModalVisible(!isChangePersonalModal)
  }

  const onChangePasswordClick = () => {
    setIsPasswordModalVisible(!isPasswordShowModal);
  }

  const onSubmitChangeInformation = async (data) => {
    try {
      const resp = await updateUserInformation(data);
      if (resp && resp.data) {
        message.success("Change information success");
      }
    } catch (e) {
      console.error(e);
    }
  }

  const onSubmitChangePassword = async (data) => {
    try {
      if (!data.confirmPassword) return;
      await updateUserPassword(data.confirmPassword);
      message.success("Change password success");
    } catch (e) {
      console.error(e);
      message.error("Failed to change password");
    }
  }

  const onModalClose = () => {
    setCurrentModal({
      modal: null,
      data: null,
    });
  }

  return (
    <Layout className="bg-white">
      {isChangePersonalModal && (
        <ChangeInformationModal onSubmit={onSubmitChangeInformation} onClose={onChangePersonalInformationClick} isVisible={isChangePersonalModal} />
      )}

      {isPasswordShowModal && (
        <ChangePasswordModal onSubmit={onSubmitChangePassword} onClose={onChangePasswordClick} isVisible={isPasswordShowModal} />
      )}
      {currentModal.modal === MODAL_RAISE_ACCESS && (
        <RequestAccessModal
          onClose={onModalClose}
          onSubmit={onRequestSubmit}
          data={{
            email: tokenData.email || "",
          }}
        />
      )}

      {currentModal.modal === MODAL_EDIT_GATEWAY && (
        <EditGatewayModal
          handleClose={onModalClose}
          data={currentModal.data}
        />
      )}

      {currentModal.modal === MODAL_MANGE_DICOM_NODE && (
        <ManageNodeModal
          handleClose={onModalClose}
          gateway_id={currentModal.data.gateway_id}
          data={currentModal.data.dicomNode}
        />
      )}

      {currentModal.modal === MODAL_EDIT_DICOM_NODE && <EditDicomNodeModal data={currentModal.data} onClose={onModalClose} />}

      <NavLogged
        onChangePasswordClick={onChangePasswordClick}
        onChangePersonalInformationClick={onChangePersonalInformationClick}
        onLogoutClick={() => {
          localStorage.clear();
          window.location.reload(true);
        }}
      />
      <Layout className="bg-white">{children}</Layout>
      <Footer />
    </Layout>
  );
};
export default DashboardLayout;
