import { subHours, subDays, format } from 'date-fns';

export const formatLayout = (value) => {
  if (!value || value === "") {
    return value;
  }
  const layout = format(value, "yyyyMMdd");
  return layout;
};

export const calculateDateRange = (value) => {
  const now = new Date();
  let startDate;

  switch (value) {
    case "last1hour":
      startDate = subHours(now, 1);
      break;
    case "today":
      startDate = new Date(now.setHours(0, 0, 0, 0));
      break;
    case "last2hours":
      startDate = subHours(now, 2);
      break;
    case "yesterday":
      startDate = new Date(now.setDate(now.getDate() - 1));
      startDate.setHours(0, 0, 0, 0);
      break;
    case "last8hours":
      startDate = subHours(now, 8);
      break;
    case "last30min":
      startDate = subHours(now, 0.5);
      break;
    case "last24hours":
      startDate = subHours(now, 24);
      break;
    case "last2Days":
      startDate = subDays(now, 2);
      break;
    case "last7Days":
      startDate = subDays(now, 7);
      break;
    case "last3hours":
      startDate = subHours(now, 3);
      break;
    case "last6hours":
      startDate = subHours(now, 6);
      break;
    case "last12hours":
      startDate = subHours(now, 12);
      break;
    default:
      startDate = now;
  }

  return {
    startDate: format(startDate, "yyyy-MM-dd'T'HH:mm:ss"),
    endDate: format(now, "yyyy-MM-dd'T'HH:mm:ss")
  };
};
