import { create } from 'zustand'
import { persist } from 'zustand/middleware';

const createImageTransferStudiesStore = (id) => create(persist(
  (set) => ({
    currentSelectedSeries: [],
    currentSeriesData: [],
    currentStudyData: null,
    clearAllStudyData: () => set({ currentStudyData: null }),
    setStudyData: (newStudyData) => set((state) => ({
      currentStudyData: state.currentStudyData ? { ...state.currentStudyData, ...newStudyData } : newStudyData,
    })),
    currentImageSharingStudyData: [],
    setImageSharingStudyData: (currentImageSharingStudyData) => set({ currentImageSharingStudyData }),
    setSeriesData: (currentSeriesData) => set({ currentSeriesData }),
    setSelectedSeries: (currentSelectedSeries) => set({ currentSelectedSeries }),
  }),
  {
    name: `imageStudiesStore-${id}`,
    getStorage: () => sessionStorage,
  }
));

const createStudiesExcelList = (id) => create(persist(
  (set) => ({
    studyWithSeriesData: {},
    setStudyWithSeriesData: (uniqueKey, newStudyWithSeriesData) => set((state) => ({
      studyWithSeriesData: {
        ...state.studyWithSeriesData,
        [uniqueKey]: newStudyWithSeriesData
      }
    }))
  }),
  {
    name: `studiesExcelList-${id}`,
    getStorage: () => sessionStorage,
  }
));


export const DashboardImageStudiesStore = createImageTransferStudiesStore('dashboard-image-studies');
export const BatchTransferingImageStudiesStore = createImageTransferStudiesStore('batch-transfering-studies');
export const BatchTransferingExcelListStore = createStudiesExcelList('batch-transfering-excel-list');