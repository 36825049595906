import React, { useState } from "react";
import { Modal, Form, Button, Spin, Input, message, Checkbox } from "antd";
import { RetrieveNodeSelection, TransferSyntaxNode, DefaultRetrieveNode, DefaultTransferSyntax } from '../../../../components/DicomNodeSelection'

export const CreateDicomNodeModal = ({ onClose, onSubmit }) => {
    const [form] = Form.useForm();
    const [isCloudPacs, setIsCloudPacs] = useState(false);
    const [isAddingDicomNode, setAddingNewDicomNode] = useState(false);
    const onFinish = async () => {
        try {
            if (isAddingDicomNode) return;
            setAddingNewDicomNode(true);
            if (isCloudPacs) {
                await onSubmit({
                    type: "cloud",
                });
            } else {
                const formData = await form.validateFields();
                await onSubmit({
                    ...formData,
                    port: parseInt(formData.port, 10),
                });
            }

            setTimeout(() => {
                onClose();
            }, 1000);
        } catch (e) {
            console.error(e);
            if (e.response && e.response.data) {
                message.error(e.response.data.message);
            }
        } finally {
            setAddingNewDicomNode(false);
        }
    }

    const handleCheckboxChange = (e) => {
        setIsCloudPacs(e.target.checked);
    };

    return (
        <Modal
            title="Create Dicom Node"
            open={true}
            onCancel={onClose}
            footer={[
                <Button key="back" onClick={onClose}>
                    Cancel
                </Button>,
                <Button
                    className="bg-blue-500"
                    key="submit"
                    type="primary"
                    onClick={onFinish}
                >
                    Save Changes
                </Button>,
            ]}
            className="sm:max-w-7xl md:max-w-7xl"
            width={600}
        >
            <Spin spinning={isAddingDicomNode}>
                <Form initialValues={{ transferSyntax: DefaultTransferSyntax, retrieve: DefaultRetrieveNode }} form={form} layout="vertical" className="pt-2.5">
                    <Form.Item>
                        <Checkbox onChange={handleCheckboxChange}>Add Cloud Pacs</Checkbox>
                    </Form.Item>
                    <Form.Item
                        rules={[{ required: true, message: "Please input node name" }]}
                        name="name"
                    >
                        <Input disabled={isCloudPacs} placeholder="Name"></Input>
                    </Form.Item>
                    <Form.Item
                        rules={[{ required: true, message: "Please input IP" }]}
                        name="ip"
                    >
                        <Input disabled={isCloudPacs} placeholder="IP"></Input>
                    </Form.Item>
                    <Form.Item
                        rules={[{ required: true, message: "Please input AET" }]}
                        name="aet"
                    >
                        <Input disabled={isCloudPacs} placeholder="AET"></Input>
                    </Form.Item>
                    <Form.Item
                        rules={[
                            { required: true, message: "Please input transferSyntax" },
                        ]}
                        name="transferSyntax"
                    >
                        {TransferSyntaxNode({ isDisabled: isCloudPacs })}
                    </Form.Item>
                    <Form.Item
                        rules={[{ required: true, message: "Please input Retrieve" }]}
                        name="retrieve"
                    >
                        {RetrieveNodeSelection({ isDisabled: isCloudPacs })}

                    </Form.Item>
                    <Form.Item
                        rules={[{ required: true, message: "Please input port" }]}
                        name="port"
                    >
                        <Input disabled={isCloudPacs} placeholder="Port" />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};
