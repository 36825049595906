import React from "react";
import { Modal, Button, Form, Input } from "antd";

export const ChangePasswordModal = ({ isVisible, onSubmit, onClose }) => {
  return (
    <Modal
      title="Change Password"
      width={500}
      visible={isVisible}
      onCancel={onClose}
      footer={[
        <Button key="close" onClick={onClose}>
          Close
        </Button>,
        <Button className="bg-blue-500 hover:bg-blue-700"
          key="submit" type="primary" form="change-password-form" htmlType="submit">
          Submit
        </Button>
      ]}
    >
      <Form
        id="change-password-form"
        layout="vertical"
        onFinish={(values) => {
          onSubmit(values);
          onClose();
        }}
      >
        <Form.Item
          name="newPassword"
          label="New Password"
          rules={[
            { required: true, message: "Please input your password!" },
            {
              pattern:
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/,
              message:
                "Password must be strong: 8+ characters\n, with uppercase,\n lowercase,\n digit,\n and special character.",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label="Confirm Password"
          dependencies={['newPassword']}
          rules={[
            { required: true, message: 'Please confirm your new password!' },
            {
              pattern:
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/,
              message:
                "Password must be strong: 8+ characters\n, with uppercase,\n lowercase,\n digit,\n and special character.",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords do not match!'));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
