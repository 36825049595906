import { create } from 'zustand'
import { persist } from 'zustand/middleware';

export const AIInboundOutboundStore = create(persist(
    (set, get) => ({
        selectedInboundGatewayId: null,
        setSelectedInboundGatewayId: (selectedInboundGatewayId) => set({ selectedInboundGatewayId }),
        getSelectedInboundGatewayId: () => {
            const { selectedInboundGatewayId } = get();
            return selectedInboundGatewayId;
        },
        selectedOutboundGatewayId: null,
        setSelectedOutboundGatewayId: (selectedOutboundGatewayId) => set({ selectedOutboundGatewayId }),
        getSelectedOutboundGatewayId: () => {
            const { selectedOutboundGatewayId } = get();
            return selectedOutboundGatewayId;
        },
        inboundList: [],
        setInboundList: (inboundList) => set({ inboundList }),
        getInBoundList: () => {
            const { inboundList } = get();
            return inboundList;
        },
        outboundList: [],
        setOutboundList: (outboundList) => set({ outboundList }),
        getOutboundList: () => {
            const { outboundList } = get();
            return outboundList;
        },
    }),
    {
        name: `ai-file-inbound`,
        getStorage: () => sessionStorage,
    }
));


export const AIGatewayStore = create(persist(
    (set, get) => ({
        gateway: [],
        setGateway: (gateway) => set({ gateway }),
        getGateway: () => {
            const { gateway } = get();
            return gateway;
        },
        dicomNodes: [],
        setDicomNodes: (dicomNodes) => set({ dicomNodes }),
        getDicomNodes: () => {
            const { dicomNodes } = get();
            return dicomNodes;
        },
    }),
    {
        name: `ai-gateway`,
        getStorage: () => sessionStorage,
    }
));

