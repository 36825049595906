import { create } from 'zustand'
import { persist } from 'zustand/middleware';

export const createTableKeySelectionKeyStore = (id) => create(persist(
    (set, get) => ({
        storedStudiesMap: new Map(),
        setStoredStudiesMap: (node) => set((state) => {
            const newMap = new Map(state.storedStudiesMap || new Map());
            newMap.set(node.rowKey, node);
            return { storedStudiesMap: newMap };
        }),
        getStoredStudiesMap: (key) => {
            const { storedStudiesMap } = get();
            return storedStudiesMap.get(key);
        },
        expandedRowKeys: [],
        toggleKeyExpand: (key) => set((state) => {
            if (state.expandedRowKeys.includes(key)) {
                return { expandedRowKeys: state.expandedRowKeys.filter((k) => k !== key) };
            } else {
                return { expandedRowKeys: state.expandedRowKeys.concat(key) };
            }
        }),
        selectedCBKeys: new Set(),
        addSelectedCBKey: (key) => set(state => {
            const newSelectedCBKeys = new Set(state.selectedCBKeys || []);
            newSelectedCBKeys.add(key);
            return { selectedCBKeys: newSelectedCBKeys };
        }),
        deleteSelectedCBKey: (key) => set(state => {
            const newSelectedCBKeys = new Set(state.selectedCBKeys || []);
            newSelectedCBKeys.delete(key);
            return { selectedCBKeys: newSelectedCBKeys };
        }),
        clearAllCBKeys: () => set({ selectedCBKeys: new Set() }),
        clearExpandedRowKeys: () => set({ expandedRowKeys: [] }),
    }),
    {
        name: `imageTransferingExpandRowKeys-${id}`,
        getStorage: () => sessionStorage,
    }
));

export const useExcelListKeyStore = create(persist(
    (set) => ({
        expandedRowKeys: [],
        toggleKeyExpand: (key) => set((state) => {
            if (state.expandedRowKeys.includes(key)) {
                return { expandedRowKeys: state.expandedRowKeys.filter((k) => k !== key) };
            } else {
                return { expandedRowKeys: state.expandedRowKeys.concat(key) };
            }
        }),
    }),
    {
        name: 'imageExcelListKeyStore',
        getStorage: () => sessionStorage,
    }
));

export const DashboardStudiesKeystore = createTableKeySelectionKeyStore('dashboard-studies');
export const DashboardCloudPacsStudiesKeystore = createTableKeySelectionKeyStore('dashboard-cloud-pacs-studies');
export const DashboardCloudPacsSeriesKeystore = createTableKeySelectionKeyStore('dashboard-cloud-pacs-series');
export const DashboardJobsKeyStore = createTableKeySelectionKeyStore('dashboard-jobs-keystore');
export const DashboardManageJobsKeyStore = createTableKeySelectionKeyStore('dashboard-manage-job-keystore');


export const createTableKeySelectionKeyStoreExcel = (id) => create(persist(
    (set, get) => ({
        storedStudiesMap: new Map(),
        setStoredStudiesMap: (node) => set((state) => {
            const newMap = new Map(state.storedStudiesMap || new Map());
            newMap.set(node.rowKey, node);
            return { storedStudiesMap: newMap };
        }),
        getStoredStudiesMap: (key) => {
            const { storedStudiesMap } = get();
            return storedStudiesMap.get(key);
        },
        expandedRowKeys: [],
        toggleKeyExpand: (key) => set((state) => {
            if (state.expandedRowKeys.includes(key)) {
                return { expandedRowKeys: state.expandedRowKeys.filter((k) => k !== key) };
            } else {
                return { expandedRowKeys: [...state.expandedRowKeys, key] };
            }
        }),
        selectedCBKeys: {},
        clearExpandedRowKeys: () => set({ expandedRowKeys: [] }),
        addSelectedCBKey: (patientID, key) => set(state => {
            const newSelectedCBKeys = new Set(state.selectedCBKeys[patientID] || []);
            newSelectedCBKeys.add(key);
            return { selectedCBKeys: {
                ...state.selectedCBKeys,
                [patientID]: newSelectedCBKeys,
            } };
        }),
        deleteSelectedCBKey: (patientID, key) => set(state => {
            const newSelectedCBKeys = new Set(state.selectedCBKeys[patientID] || []);
            newSelectedCBKeys.delete(key);
            return { selectedCBKeys: {
                ...state.selectedCBKeys,
                [patientID]: newSelectedCBKeys,
            } };
        }),
        clearAllCBKeys: () => set({ selectedCBKeys: {} }),
    }),
    {
        name: `imageTransferingExpandRowKeys-${id}`,
        getStorage: () => sessionStorage,
    }
));

export const BatchTransferingStudiesKeystore = createTableKeySelectionKeyStoreExcel('batch-transfering-studies');

