import React, { useState } from "react";
import { message } from "antd";
import {
  Modal,
  Form,
  Button,
  Select,
  Table,
  Spin,
  InputNumber,
  Input,
  Typography,
  Popconfirm,
} from "antd";
import { updateListenerAi } from "../../../../apis/ai";
import {
  transferSyntax,
  mapValueTransferSyntax,
} from "../../../constants/dicomNode";

const TransferSyntaxNode = (
  <Select>
    {transferSyntax &&
      transferSyntax.map((transferSyntax) => {
        return (
          <Select.Option value={transferSyntax}>
            {mapValueTransferSyntax[transferSyntax]}
          </Select.Option>
        );
      })}
  </Select>
);

const errorMessageMapping = {
  listener_name: "Please input listener name",
  listener_ip: "Please input listener ip",
  listener_port: "Please input listener port",
  listener_aet: "Please input listener aet",
  listener_transfersyntax: "Please input listener transfer syntax",
}

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  let inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  if (dataIndex === "listener_transfersyntax") {
    inputNode = TransferSyntaxNode;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item rules={[{ required: true, message: `${errorMessageMapping[dataIndex] || "Field cannot be leave empty"}` }]}
          name={dataIndex} style={{ margin: 0 }}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const EditListenerModal = ({
  selectedGatewayID,
  isOpen,
  data,
  handleClose,
}) => {
  const filteredListeneredByGateID = data.filter((data) => {
    return data.id === selectedGatewayID
  });

  const save = async (record) => {
    try {
      const row = await listenerForm.validateFields();
      if (!row || !row.listener_name || row.listener_name.trim().length === 0) {
        return;
      }
      await updateListenerAi(record.id, {
        name: row.listener_name,
        ip: row.listener_ip,
        port: row.listener_port,
        aet: row.listener_aet,
        transferSyntax: row.listener_transfersyntax,
      });

      message.success("Updated listener data complete");
      setTimeout(() => {
        window.location.reload(true);
      }, 1500);
    } catch (e) {
      message.error("Please input all required fields");
      console.error(e);
    }
  };
  
  const isEditing = (record) => record.id === editingKey;
  const [editingKey, setEditingKey] = useState("");
  const edit = (record) => {
    listenerForm.setFieldsValue({
      ...record,
      listener_ip: '',
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      hidden: true,
    },
    {
      title: "Name",
      dataIndex: "listener_name",
      key: "listener_name",
      className: "text-sm p-2",
      filterSearch: true,
      editable: true,
      onFilter: (value, record) => {
        return record.Tag.indexOf(value) >= 0;
      },
    },
    {
      title: "IP",
      dataIndex: "listener_ip",
      key: "listener_ip",
      className: "text-sm p-2",
      editable: true,
    },
    {
      title: "Port",
      dataIndex: "listener_port",
      key: "listener_port",
      className: "text-sm p-2",
      editable: true,
    },
    {
      title: "AET",
      dataIndex: "listener_aet",
      key: "listener_aet",
      className: "text-sm p-2",
      editable: true,
    },
    {
      title: "TransferSyntax",
      dataIndex: "listener_transfersyntax",
      key: "listener_transfersyntax",
      className: "text-sm p-2",
      editable: true,
    },
    {
      title: "Status",
      dataIndex: "listener_status",
      key: "listener_status",
      className: "text-sm p-2",
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      className: "w-[150px] text-sm p-2",
      render: (value, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link style={{ marginRight: 8 }}>
              <Popconfirm
                title="Do you want to update?"
                okButtonProps={{ className: "bg-blue-600" }}
                onConfirm={() => {
                  save(record, value);
                }}
              >
                Save
              </Popconfirm>
            </Typography.Link>
            <Popconfirm
              okButtonProps={{ className: "bg-blue-600" }}
              title="Do you want to cancel?"
              onConfirm={cancel}
            >
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <div className="flex">
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const modalFooter = (
    <div>
      <Button onClick={handleClose}>Close</Button>
    </div>
  );

  const onClose = () => {
    handleClose();
    listenerForm.resetFields();
  };

  const [listenerForm] = Form.useForm();
  return (
    <Modal
      className=""
      title="Manage Listener"
      open={isOpen}
      closable={true}
      footer={modalFooter}
      width={1000}
      onCancel={onClose}
    >
      <Form form={listenerForm} layout="vertical" name="manageListener">
        {!filteredListeneredByGateID || (filteredListeneredByGateID.length === 0 && <Spin />)}
        {filteredListeneredByGateID && filteredListeneredByGateID.length > 0 && (
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            className="w-full"
            dataSource={filteredListeneredByGateID}
            columns={mergedColumns}
          />
        )}
      </Form>
    </Modal>
  );
};
