import React, { useEffect, useState } from "react";
import { Modal, Tabs, Button } from "antd";
import { JobsTable } from "./JobTable";
import { listJobs, listRunningJobs } from "../../../apis/job-status";
import { getJobsDetail } from '../../../apis/job-status';

export const ManageJobs = ({ isOpen, handleClose, onCancelJobs }) => {
  const [logModalVisible, setLogModalVisible] = useState(false);
  const [currentLog, setCurrentLog] = useState(null);
  const onViewJobDetailLog = async (jobId) => {
    try {
      if (!jobId) {
        return;
      }
      const jobResponse = await getJobsDetail(jobId);
      const msg = jobResponse.data?.[0]?.msg || 'No logs found';
      setCurrentLog(msg);
      setLogModalVisible(true);
    } catch (error) {
      console.error(error);
    }
  }

  const modalFooter = (
    <div>
      <Button
        onClick={() => {
          handleClose();
        }}
      >
        Close
      </Button>
    </div>
  );
  const [jobs, setJobs] = useState([]);
  const [runningJobs, setRunningJobs] = useState([]);

  const items = [
    {
      key: "1",
      label: "Jobs Running",
      children: <JobsTable onViewLog={onViewJobDetailLog} onJobCancel={onCancelJobs} data={runningJobs} />,
    },
    {
      key: "2",
      label: "All Jobs",
      children: <JobsTable onViewLog={onViewJobDetailLog} data={jobs} />,
    },
  ];

  useEffect(() => {
    const fetchAllJob = async () => {
      try {
        const response = await listJobs();
        if (response && response.data) {
          setJobs(response.data);
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchAllJob();
  }, []);

  useEffect(() => {
    const fetchRunningJobs = async () => {
      try {
        const response = await listRunningJobs();
        if (response && response.data) {
          setRunningJobs(response.data);
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchRunningJobs();
  }, []);

  return (
    <Modal
      title="Manage Jobs History"
      open={isOpen}
      styles={{ content: { width: "1200px" } }}
      closable={true}
      footer={modalFooter}
      className="sm:max-w-7xl md:max-w-7xl"
      width={1200}
      onCancel={() => {
        if (handleClose) {
          handleClose();
        }
      }}
    >
      <Tabs defaultActiveKey="1" items={items} />
      {logModalVisible && <Modal
        title="Job Logs"
        visible={logModalVisible}
        onCancel={() => setLogModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setLogModalVisible(false)}>
            Close
          </Button>,
        ]}
      >
        <p>{currentLog}</p>
      </Modal>}
    </Modal>
  );
};
